#reportMessage{
    width: 98%;
    height: 300px;
    resize: none;
}

.reportBtn{
    padding: 10px 20px;
    border: none;
    background-color: #C4E5E0;
    border-radius: 10px;
    float: right;
    cursor: pointer;
}
 