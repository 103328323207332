#lockIcon {
    font-size: 2em;
    /* margin-top: 4%;
    margin-bottom: 4%; */
    margin-right: 1%;
}

#OTPTitle {
    font-size: 25px;
    
    
}

#OTPDefine {
    width: 100%;
    margin-bottom: 2%;
}

#phone {
    padding: 7px 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid grey;
    width: 75%;
}
#referenceCode{
    width: 100%;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid grey;
}
.OTPBtn {
    color: black;
    padding: 10px 20px;
    border: none;
    background-color: #C4E5E0;
    border-radius: 10px;
    float: right;
    cursor: pointer;
}

.otpBox{
    width: 13%;
    margin: 1%;
    height: 65px;
    border-radius: 10px;
    text-align: center;
    border: 1px solid black;
}

@media (max-width:480px) {
    .otpBox{
        width: 9%;
    }
}