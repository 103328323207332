.modalNewProject {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(90, 90, 90, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}
.characterCount{
    font-size: 14px;
    color: grey;
}
.modal-contentAcknowledge {
    /* border-radius: 10px; */
    width: 700px;
    /* margin-top: 5%; */
    background-color: #fff;
    box-shadow: 0 10px 20px rgb(0, 0 ,0,0.5);
}

.modalBoost{
    /* border-radius: 10px; */
    width: 50%; /*35*/
    padding-left: 16px;
    padding-right: 16px;
    max-height: 600px;
    background-color: #fff;
    overflow-y: auto;
    box-shadow: 0 10px 20px rgb(0, 0 ,0,0.5);
}

.modal-contentNewProject {
    /* border-radius: 10px; */
    width: 50%;
    max-height: 600px;
    background-color: #fff;
    overflow-y: auto;
    box-shadow: 0 10px 20px rgb(0, 0 ,0,0.5);
}

.notification-close {
    float: right;
    display: flex;
    justify-content: center;
    font-size: 30px;
    width: 5%;
    cursor: pointer;

}

.modal-headerNewProject,
.modal-footerNewProject,
.modal-bodyNewProject {
    padding: 5px;
    padding-bottom: 15px;
}

.modal-headerNewProject {
    /* border-bottom: 1px #ccc solid; */
}

.modal-footerNewProject {
    /* border-top: 1px #ccc solid; */
}

.modal-footerNewProject p {
    color: grey;
}

.addPropertyInput {
    width: 100%;
    padding: 8px 5px;
    border: 1px grey solid;
    border-radius: 10px;
    margin-top: 5px
}

.addPropertyTitle {
    font-weight: bold;
}


.modal-titleNewProject {
    text-align: center;
    font-size: 25px;
    padding-top: 15px;
}

.modal-titleNewListing{
    font-size: 25px;
    padding: 8px 10px;
    margin-top: 3%;
}

#addPropertyBtn {
    color: black;
    padding: 10px 20px;
    border: none;
    background-color: #C4E5E0;
    /* border-radius: 10px; */
    margin-right: 1%;
    float: right;
    cursor: pointer;
}

#canvaSign{
    border: 1px solid grey;
    border-radius: 10px;
}
/* .modal-bodyNewProject label{
    display: inline-block;
    width: 35%;
    text-align: left;
    padding: 10px;
} */
 

.defaultUploadBtn, .custom-file-upload, #file-upload  {
    background-color: #33434D;
    color:white;
    border-radius: 10px;
    margin: 10px 0 10px 0;
    padding: 8px 10px 8px 10px;
    font-size: 13px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
    width: 30%;
  }

  .uploadBtn{
    background-color: #33434D;
    color:white;
    border-radius: 10px;
    margin: 10px 0 10px 0;
    padding: 8px 10px 8px 10px;
    font-size: 13px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
    width: 70%;
  }

  .defaultUploadBtn:hover{
    transition: all 0.3s ease-out;
    background: #33434da9;
    transition: 250ms;
  }
  
.modalLabel {
    margin-bottom: 1%;
    margin-top: 3%;
    font-weight: bold;
}


.checkboxLabel {
    margin-left: 2%;
}

.newProjectImportantRequirementsLeft{
    float: left;
    width: 50%;
}

.newProjectImportantRequirementsRight{
    float:right;
    width: 50%;
}


.newListingInput,
.newListingSelect {
    padding: 8px 0 8px 0;
    color: black;
    width: 98%;
    border-radius: 10px;
    border:1px solid #ccc;
    text-align: center;

}

.newListingSelectMulti{
    padding: 8px 0 8px 0;
    width: 95%;
    border-radius: 10px;
    border:1px solid #ccc;
    text-align: center;
}


.newListingInputSpecial,
.newListingSelectSpecial {
    padding: 8px 0 8px 0;
    width: 30%;
    text-align: center;
}

.rangeDiv {
    width: 60%;
    float: right;
    margin-right: 5%;
    margin-top: 1%;
}

.invalid {
    padding: 8px 0 8px 0;
    width: 98%;
    border-radius: 10px;
    text-align: center;
    border: 2px solid red;
}

.invalid_special {
    padding: 8px 0 8px 0;
    width: 50%;
    text-align: center;
    border-radius: 10px;
    border: 2px solid red;
}

.rangeBox {
    background-color: rgb(98, 125, 177);
    border-radius: 10px;
    color: white;
    margin-bottom: 5%;
    margin-top: 1%;
}

#croppedImg{
    width: 50px;
    height: 50px;
}

/* .modal-bodyNewProject p{
    font-size: 13px;
    margin: 1% 0 0 35%;
    width: 60%;
    text-align: center;
} */

.modal-acknowledge p {
    text-align: center;
    /* font-size: 20px; */
    width: 100%;
    padding: 10px;
}

#additionalInfoLabel {
    width: 60%;
}

#additionalInfo {
    width: 35%;

}

.modal-left {
    float: left;
    width: 50%;
}

.modal-right {
    float: right;
    width: 50%;
}


/* Dropdown Button */
.dropbtn {
    background-color: #33434D;
    color: white;
    padding: 14px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    max-height: 450px;
    overflow-y: auto;
    background-color: #FAFAFA;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: rgba(98, 125, 177, 0.47);
    ;
}

.modal-contentDefaultImage {
    border-radius: 10px;
    width: 1400px;
    max-height: 550px;
    margin-top: 5%;
    background-color: #fff;
    overflow-y: auto;
}

.defaultPhoto {
    width: 300px;
    height: 200px;
    margin: 2% 1% 2% 1%;
}


.defaultPhotoPotrait {
    width: 200px;
    height: 300px;
    margin: 2% 1% 2% 1%;
}

.defaultPhotoSelect {
    width: 300px;
    height: 200px;
    margin: 2% 1% 2% 1%;
    opacity: 0.5;
    /* z-index: -1; */
}

.defaultPhotoSelectPotrait {
    width: 200px;
    height: 300px;
    margin: 2% 1% 2% 1%;
    opacity: 0.5;
    /* z-index: -1; */
}


#file-upload {
    /* display: none; */
}


/* .custom-file-upload {
    border: 2px solid rgb(98, 125, 177);
    margin: 10px 0 10px 0;
    padding: 8px 10px 8px 10px;
    display: inline-block;
    border-radius: 50px;
    color: rgb(98, 125, 177);
    font-size: 13px;
    cursor: pointer;
} */

#defaultFixedMenu {
    position: fixed;
    width: inherit;
    background-color: rgba(232, 227, 227, 0.93);
}

#defaultButton {
    float: left;
    width: 80%;
}

#defaultButtonClose {
    float: right;
    width: 20%;
}

.stepper_tab {
    display: none;
    margin: 1%;
}

.step {
    height: 10px;
    width: 10px;
    margin: 0 2px;
    background-color: #bbbbbb;
    border: none;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.5;
}


.step.activeStep {
    opacity: 1;
}


.loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #3498db;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

.modal-bodyUserType {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;

}

.userTypeNotActive {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
    text-align: center;
    width: 20%;
    background-color: #d0faf4;
    border-radius: 10px;
    padding: 80px 40px;
    cursor: pointer;
}

.userTypeNotActive i,
.userTypeActive i {
    font-size: 2rem;
    /* adjust as needed */
    margin-bottom: 0.5rem;
    /* add some spacing between the icon and text */
}

#typeOfSpace{
    float: right;
    border-radius: 10px;
    padding: 5px;
    border: 1px solid #c0c0c0;
}

#saveResidentialBtn{
    border-radius: 10px;
    background-color: #33434D;
    color: white;
    padding: 3px 8px;
    cursor: pointer;
}

.notSelect{
    background-color: #33434D;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    color: white;
    padding: 8px 8px;
    cursor: pointer;
    border-radius: 10px;
}
.notSelectedAdvertiseDates:hover{
    background-color: #c1c1c1;
}
.selectedAdvertiseDates{
    background-color: #c1c1c1;
}

.select{
    background-color: #556e7d;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    color: white;
    padding: 8px 8px;
    cursor: pointer;
    border-radius: 10px;
}

.notSelect:hover{
    background-color: #556e7d;
}

.userTypeActive {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
    text-align: center;
    width: 20%;
    background-color: #C4E5E0;
    border-radius: 10px;
    padding: 80px 40px;
    cursor: pointer;
}

.modal-contentUserType {
    min-width: 50%;
    /* border-radius: 10px; */
    max-height: 700px;
    background-color: #fff;
    overflow-y: auto;
    box-shadow:  0 10px 20px rgb(0, 0 ,0,0.5);;
}

#tierTab {
    display: none;
}
#tierTabPaymentTier{
    display: none;
}
.userTierActive, .userTierActivePayment {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    margin: 0 10px;
    border-radius: 10px;
    background-color: #467099;
    cursor: pointer;
}

.userTierActive #userTierTitle, .userTierActivePayment #userTierTitle{
    padding-top: 55px;
    padding-bottom: 20px;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: white;
}

#bonusDiv{
    position: absolute;
    top: 0;
    background-color: #F0D27B;
    padding: 5px;
    width: 100%;
    border-radius: 10px;
    text-align: center;
}

.userTierActive #userTier2ndRow, .userTierActivePayment #userTier2ndRow {
    background-color: #D0DEED;
    width: 100%;
    padding-top: 20px;
    min-height: 175px;
    justify-content: center;
    flex-direction: column;
    display: flex;
    padding-bottom: 20px;
    text-align: center;
}

.userTierActive #userTier2ndRow #tierPrice, .userTierActivePayment #userTier2ndRow #tierPrice{
    color: #467099;
    font-weight: bold;
    font-size: 50px;
}

.userTierActive #userTier2ndRow #tierPriceMore, .userTierActivePayment #userTier2ndRow #tierPriceMore{
    color: #FF3C3C;
    font-size: 15px;
    font-weight: bold;

}

.userTierActive #userTier3ndRow, .userTierActivePayment #userTier3ndRow {
    color: white;
    width: 100%;
    padding: 10px 10px;
    text-align: center;
}

.userTierActive #userTier3ndRow i, .userTierActivePayment #userTier3ndRow i{
    margin-right: 5%;
}



.userTierNotActive, .userTierNotActivePayment {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
    width: 50%;
    border-radius: 10px;
    cursor: pointer;
}

.userTierNotActive #userTierTitle, .userTierNotActivePayment #userTierTitle {
    color: #467099;
    padding-top: 55px;
    padding-bottom: 20px;
    width: 100%;
    text-align: center;

    font-weight: bold;
}

.userTierNotActive #userTier2ndRow, .userTierNotActivePayment #userTier2ndRow{
    background-color: #E6E6E6;
    width: 100%;
    padding-top: 20px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    min-height: 175px;
    padding-bottom: 20px;
    text-align: center;
}

.userTierNotActive #userTier2ndRow #tierPrice, .userTierNotActivePayment #userTier2ndRow #tierPrice{
    color: #467099;
    font-weight: bold;
    font-size: 50px;
}

.userTierNotActive #userTier2ndRow #tierPriceMore,  .userTierNotActivePayment #userTier2ndRow #tierPriceMore{
    color: #FF3C3C;
    font-size: 15px;
    font-weight: bold;

}

.userTierNotActive #userTier3ndRow,  .userTierNotActivePayment #userTier3ndRow{

    width: 100%;
    padding: 10px 10px;
    text-align: center;
}

.userTierNotActive #userTier3ndRow i, ,  .userTierNotActivePayment #userTier3ndRow i {
    margin-right: 5%;
}

.boostAllCol1,
.boostAllCol2 {
    display: inline-block;
    vertical-align: top;
}

.boostAllCol1 {
    width: 50%;
    /* adjust as needed */
}

.boostAllCol2 {
    width: 50%;
    /* adjust as needed */
}

.eachBoostOffer {
    border: 1px solid #ccc;
    padding: 10px 10px;
    border-radius: 10px;
    margin: 3%;
    text-align: center;
    min-height: 120px;
    display: flex;
    align-items: center;
    /* aligns vertically */
    justify-content: center;
    /* aligns horizontally */
    flex-direction: column;
    cursor: pointer;
    /* stacks items vertically */
}

.eachBoostOfferSelected{
    border: 1px solid #ccc;
    padding: 10px 10px;
    border-radius: 10px;
    margin: 3%;
    text-align: center;
    min-height: 120px;
    display: flex;
    align-items: center;
    /* aligns vertically */
    justify-content: center;
    /* aligns horizontally */
    flex-direction: column;
    cursor: pointer;
    background-color: #8CD1C7;
}

.eachBoostAmount {
    /* font-weight: bold; */
    font-size: 40px;
}

.boostBtn{
    color: white;
    background-color: #33434D;
    padding: 8px 20px;
    /* border-radius: 10px; */
    cursor: pointer;
    float: right;
    margin-right: 1%;
}

.newProjectminSqFeet{
    float: left;
    width: 50%;
}

.newProjectmaxSqFeet{
    float: right;
    width: 50%;
}

#newProjectMinLeasePeriod{
    float: left;
    width: 50%;
}
#newProjectMaxLeasePeriod{
    float: right;
    width: 50%;
}

#newListingLeft{
    float: left;
    width: 50%;
}

#newListingRight{
    float: right;
    width: 50%;
}

.newListingBtn{
    color: black;
    padding: 10px 20px;
    border: none;
    background-color: #C4E5E0;
    /* border-radius: 10px; */
    float: right;
    margin-right: 1%;
    cursor: pointer;
}
#preferredListingSelectAutoFill{
    padding: 10px 10px;
    border-radius: 10px;
    border: none;
    background-color: #33434D;
    color: white;
}
.newListingInputUSP{
    height: 200px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
    resize: none;
    line-height: 1.5; /* Set the line height to increase space between lines */
    white-space: pre-wrap; /* Wrap the text to the next line when it reaches the end of the input box */
    text-align: left; /* Set the text alignment to left */
    vertical-align: top; /* Align the text to the top of the input box */
}
#changeUsernameLabel{
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 1%;
    text-align: left;
}

#addPropertyEmailBtn{
    padding: 3px 10px;
    border-radius: 10px;
    background-color: #33434D;
    color: white;
    cursor: pointer;
}

.addPropertyEmailNotiBtn {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 50px;
    color: white;
    height: 20px;
  }
  
  .addPropertyslider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    transition: 0.4s;
  }
  
  .on {
    background-color: #33434D;
    cursor: pointer;
    padding-bottom: 23px;
  }
  
  .off {
    transform: translateX(30px);
    background-color: #be3434;
    cursor: pointer;
    padding-bottom: 23px;
  }

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

#thisImage{
    width: 400px;
    height: 500px;
}



@media only screen and (max-width: 412px) {
    .newListingSelectMulti{
        width: 93%;
    }
    #thisImage{
        width: 250px;
        height: 350px;
    }
    #croppedImg{
        width: 35px;
        height: 35px;
    }
    
    .modal-contentUserType,.modalBoost,.modal-contentNewProject{
        width: 90%;
        overflow-y: auto;
    }
    .defaultUploadBtn, .custom-file-upload,.uploadBtn,.uploadBtn{
        width: 100%;
    }
    #file-upload{
        width: 96%;
    }
    .newProjectImportantRequirementsLeft,.newProjectImportantRequirementsRight,#newListingLeft,#newListingRight,.newProjectminSqFeet,.newProjectmaxSqFeet,#newProjectMinLeasePeriod,
    #newProjectMaxLeasePeriod{
        width: 100%;
        float: none;
    }
    .dropbtn,.newListingBtn{
        padding: 8px 10px;
    }
    .defaultPhoto,.defaultPhotoSelect{
        width: 150px;
        height: 100px
    }
    .defaultPhotoSelectPotrait,.defaultPhotoPotrait{
        width: 100px;
        height: 150px;
    }
    .modal-bodyUserType{
        flex-direction: column;
    }
    .userTierNotActive, .userTierNotActivePayment,.userTierActive, .userTierActivePayment,.userTypeActive,.userTypeNotActive{
        width: 100%;
    }
    .userTypeNotActive,.userTypeActive{
        margin-top: 1%;
        margin-bottom: 1%;
    }
    .modal-titleNewProject,.userTierNotActive #userTierTitle, .userTierNotActivePayment #userTierTitle,.modalLabel,.addPropertyTitle,.modal-acknowledge{
        font-size: 16px;
    }
}
@media only screen and (min-width: 412px) and (max-width: 540px) {
    .newListingSelectMulti{
        width: 93%;
    }
    #thisImage{
        width: 250px;
        height: 350px;
    }
    #croppedImg{
        width: 35px;
        height: 35px;
    }
    .modal-contentUserType,.modalBoost,.modal-contentNewProject{
        width: 90%;
        overflow-y: auto;
    }
    .defaultUploadBtn, .custom-file-upload,.uploadBtn,.uploadBtn{
        width: 100%;
    }
    #file-upload{
        width: 96%;
    }
    .newProjectImportantRequirementsLeft,.newProjectImportantRequirementsRight,#newListingLeft,#newListingRight,.newProjectminSqFeet,.newProjectmaxSqFeet,#newProjectMinLeasePeriod,
    #newProjectMaxLeasePeriod{
        width: 100%;
        float: none;
    }
    .dropbtn,.newListingBtn{
        padding: 8px 10px;
    }
    .defaultPhoto,.defaultPhotoSelect{
        width: 150px;
        height: 100px
    }
    .defaultPhotoSelectPotrait,.defaultPhotoPotrait{
        width: 100px;
        height: 150px;
    }
    .modal-bodyUserType{
        flex-direction: column;
    }
    .userTierNotActive, .userTierNotActivePayment,.userTierActive, .userTierActivePayment,.userTypeActive,.userTypeNotActive{
        width: 100%;
    }
    .userTypeNotActive,.userTypeActive{
        margin-top: 1%;
        margin-bottom: 1%;
    }
    .modal-titleNewProject,.userTierNotActive #userTierTitle, .userTierNotActivePayment #userTierTitle,.modalLabel,.addPropertyTitle,.modal-acknowledge{
        font-size: 16px;
    }
}
@media only screen and (min-width: 540px) and (max-width: 768px) {
    .newListingSelectMulti{
        width: 93%;
    }
    #thisImage{
        width: 250px;
        height: 350px;
    }
    #croppedImg{
        width: 35px;
        height: 35px;
    }
    .modal-contentUserType,.modalBoost,.modal-contentNewProject{
        width: 90%;
        overflow-y: auto;
    }
    .defaultUploadBtn, .custom-file-upload,.uploadBtn,.uploadBtn{
        width: 100%;
    }
    #file-upload{
        width: 96%;
    }
    .newProjectImportantRequirementsLeft,.newProjectImportantRequirementsRight,#newListingLeft,#newListingRight,.newProjectminSqFeet,.newProjectmaxSqFeet,#newProjectMinLeasePeriod,
    #newProjectMaxLeasePeriod{
        width: 100%;
        float: none;
    }
    .dropbtn,.newListingBtn{
        padding: 8px 10px;
    }
    .defaultPhoto,.defaultPhotoSelect{
        width: 150px;
        height: 100px
    }
    .defaultPhotoSelectPotrait,.defaultPhotoPotrait{
        width: 100px;
        height: 150px;
    }
    .modal-bodyUserType{
        flex-direction: column;
    }
    .userTierNotActive, .userTierNotActivePayment,.userTierActive, .userTierActivePayment,.userTypeActive,.userTypeNotActive{
        width: 100%;
    }
    .userTypeNotActive,.userTypeActive{
        margin-top: 1%;
        margin-bottom: 1%;
    }
    .modal-titleNewProject,.userTierNotActive #userTierTitle, .userTierNotActivePayment #userTierTitle,.modalLabel,.addPropertyTitle,.modal-acknowledge{
        font-size: 16px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
    .newListingSelectMulti{
        width: 93%;
    }
    #thisImage{
        width: 250px;
        height: 350px;
    }
    #croppedImg{
        width: 35px;
        height: 35px;
    }
    .modal-contentUserType,.modalBoost,.modal-contentNewProject{
        width: 90%;
        overflow-y: auto;
    }
    .defaultUploadBtn, .custom-file-upload,.uploadBtn,.uploadBtn{
        width: 100%;
    }
    #file-upload{
        width: 96%;
    }
    .newProjectImportantRequirementsLeft,.newProjectImportantRequirementsRight,#newListingLeft,#newListingRight,.newProjectminSqFeet,.newProjectmaxSqFeet,#newProjectMinLeasePeriod,
    #newProjectMaxLeasePeriod{
        width: 100%;
        float: none;
    }
    .dropbtn,.newListingBtn{
        padding: 8px 10px;
    }
    .defaultPhoto,.defaultPhotoSelect{
        width: 150px;
        height: 100px
    }
    .defaultPhotoSelectPotrait,.defaultPhotoPotrait{
        width: 100px;
        height: 150px;
    }
    .modal-bodyUserType{
        flex-direction: column;
    }
    .userTierNotActive, .userTierNotActivePayment,.userTierActive, .userTierActivePayment,.userTypeActive,.userTypeNotActive{
        width: 100%;
    }
    .userTypeNotActive,.userTypeActive{
        margin-top: 1%;
        margin-bottom: 1%;
    }
    .modal-titleNewProject,.userTierNotActive #userTierTitle, .userTierNotActivePayment #userTierTitle,.modalLabel,.addPropertyTitle,.modal-acknowledge{
        font-size: 16px;
    }
}
@media only screen and (min-width: 800px) and (max-width: 850px) {
    .newListingSelectMulti{
        width: 93%;
    }
    #thisImage{
        width: 250px;
        height: 350px;
    }
    #croppedImg{
        width: 35px;
        height: 35px;
    }
    .modal-contentUserType,.modalBoost,.modal-contentNewProject{
        width: 90%;
        overflow-y: auto;
    }
    .defaultUploadBtn, .custom-file-upload,.uploadBtn,.uploadBtn{
        width: 100%;
    }
    #file-upload{
        width: 96%;
    }
    .newProjectImportantRequirementsLeft,.newProjectImportantRequirementsRight,#newListingLeft,#newListingRight,.newProjectminSqFeet,.newProjectmaxSqFeet,#newProjectMinLeasePeriod,
    #newProjectMaxLeasePeriod{
        width: 100%;
        float: none;
    }
    .dropbtn,.newListingBtn{
        padding: 8px 10px;
    }
    .defaultPhoto,.defaultPhotoSelect{
        width: 150px;
        height: 100px
    }
    .defaultPhotoSelectPotrait,.defaultPhotoPotrait{
        width: 100px;
        height: 150px;
    }
    .modal-bodyUserType{
        flex-direction: column;
    }
    .userTierNotActive, .userTierNotActivePayment,.userTierActive, .userTierActivePayment,.userTypeActive,.userTypeNotActive{
        width: 100%;
    }
    .userTypeNotActive,.userTypeActive{
        margin-top: 1%;
        margin-bottom: 1%;
    }
    .modal-titleNewProject,.userTierNotActive #userTierTitle, .userTierNotActivePayment #userTierTitle,.modalLabel,.addPropertyTitle,.modal-acknowledge{
        font-size: 16px;
    }
}
@media only screen and (min-width: 850px) and (max-width: 992px) {
    .newListingSelectMulti{
        width: 93%;
    }
    #thisImage{
        width: 250px;
        height: 350px;
    }
    #croppedImg{
        width: 35px;
        height: 35px;
    }
    .modal-contentUserType,.modalBoost,.modal-contentNewProject{
        width: 90%;
        overflow-y: auto;
    }
    .defaultUploadBtn, .custom-file-upload,.uploadBtn,.uploadBtn{
        width: 100%;
    }
    #file-upload{
        width: 96%;
    }
    .newProjectImportantRequirementsLeft,.newProjectImportantRequirementsRight,#newListingLeft,#newListingRight,.newProjectminSqFeet,.newProjectmaxSqFeet,#newProjectMinLeasePeriod,
    #newProjectMaxLeasePeriod{
        width: 100%;
        float: none;
    }
    .dropbtn,.newListingBtn{
        padding: 8px 10px;
    }
    .defaultPhoto,.defaultPhotoSelect{
        width: 150px;
        height: 100px
    }
    .defaultPhotoSelectPotrait,.defaultPhotoPotrait{
        width: 100px;
        height: 150px;
    }
    .modal-bodyUserType{
        flex-direction: column;
    }
    .userTierNotActive, .userTierNotActivePayment,.userTierActive, .userTierActivePayment,.userTypeActive,.userTypeNotActive{
        width: 100%;
    }
    .userTypeNotActive,.userTypeActive{
        margin-top: 1%;
        margin-bottom: 1%;
    }
    .modal-titleNewProject,.userTierNotActive #userTierTitle, .userTierNotActivePayment #userTierTitle,.modalLabel,.addPropertyTitle,.modal-acknowledge{
        font-size: 16px;
    }
}