.inputSignUp {
  width: 30%;
  outline: none;
  text-align: center;
  padding: 10px 10px;
  margin-bottom: 1%;
  border: 1px solid rgb(173, 173, 173);
  border-radius: 10px;
}

#passwordDiv{
  width: 30%;
}

#bottomSignUp{
  width: 30%;
}

#keywordForget {
  color: #316391;
  cursor: pointer;
  text-align: left;
  float: left;
}

#lookingSpace{
  background-color: #32444e;
  color: white;
  width: 48%;
  margin: 1%;
  padding-top: 80px;
  padding-bottom: 80px;
  border-radius: 10px;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;
}

#primaryRoleSubTier:hover{
  background-color:#5f7a89;
  color: white;
  border: 1px solid #5f7a89;
}

#gotSpace{
  background-color: #8cd1c7;
  width: 48%;
  margin: 1%;
  padding-top: 80px;
  padding-bottom: 80px;
  border-radius: 10px;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;
}

#lookingSpace:hover{
  background-color: #5f7a89;
}

#gotSpace:hover{
  background-color: #b0fff3;
}

#LoginButton {
  background-color: #33434D;
  color: white;
  padding: 8px 20px;
  border-radius: 10px;
  cursor: pointer;
  float: right;
}

.paraSignUp {
  width: 30%;
  text-align: justify;
  margin-top: 2%;
}

.keywordSignUp {
  color: #316391;
  cursor: pointer;
  text-decoration: none;
}

.btn--facebook {
  width: 30%;
  margin-top: 1%;
  padding: 8px 20px;
  border-radius: 10px;
}

.resetPasswordBtn {
  background-color: #33434D;
  color: white;
  padding: 8px 20px;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 3%;
}

#ORWordSignUp{
  margin: 0;
}
@media only screen  and (max-width: 280px) {

  .inputSignUp,#bottomSignUp,#passwordDiv{
    width: 90%;
  }
  
  .btn--facebook{
    width: 90%;
  }
  #signUpDiv h1{
    font-size: 20px;
  }

  
#ORWordSignUp{
  margin-top: 5%;
  margin-bottom: 5%;
}
  
}
@media only screen and (min-width: 320px) and (max-width: 360px) {

  .inputSignUp,#bottomSignUp,#passwordDiv{
    width: 90%;
  }
 
  .btn--facebook{
    width: 90%;
  }
  #signUpDiv h1{
    font-size: 20px;
  }
  #ORWordSignUp{
    margin-top: 5%;
    margin-bottom: 5%;
  }
}
@media only screen and (min-width: 360px) and (max-width: 390px) {

  .inputSignUp,#bottomSignUp,#passwordDiv{
    width: 90%;
  }
  
  .btn--facebook{
    width: 90%;
  }
  #signUpDiv h1{
    font-size: 20px;
  }
  #ORWordSignUp{
    margin-top: 5%;
    margin-bottom: 5%;
  }
}
@media only screen and  (min-width: 390px) and (max-width: 430px) {
  .inputSignUp,#bottomSignUp,#passwordDiv{
    width: 90%;
  }
  
  .btn--facebook{
    width: 90%;
  }
  #signUpDiv h1{
    font-size: 20px;
  }
  #ORWordSignUp{
    margin-top: 5%;
    margin-bottom: 5%;
  }

}
@media only screen and (min-width: 430px) and (max-width: 540px) {
  .inputSignUp,#bottomSignUp,#passwordDiv{
    width: 90%;
  }
  
  .btn--facebook{
    width: 90%;
  }
  #signUpDiv h1{
    font-size: 20px;
  }

  #ORWordSignUp{
    margin-top: 5%;
    margin-bottom: 5%;
  }

}
@media only screen and (min-width: 540px) and (max-width: 768px) {
  .inputSignUp,#bottomSignUp,#passwordDiv{
    width: 90%;
  }
  
  .btn--facebook{
    width: 90%;
  }
  #signUpDiv h1{
    font-size: 20px;
  }
  #ORWordSignUp{
    margin-top: 5%;
    margin-bottom: 5%;
  }

}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  .inputSignUp,#bottomSignUp,#passwordDiv{
    width: 90%;
  }
 
  .btn--facebook{
    width: 90%;
  }
  #signUpDiv h1{
    font-size: 20px;
  }
  #ORWordSignUp{
    margin-top: 5%;
    margin-bottom: 5%;
  }

}
@media only screen and (min-width: 800px) and (max-width: 850px) {
  .inputSignUp,#bottomSignUp,#passwordDiv{
    width: 90%;
  }
 
  .btn--facebook{
    width: 90%;
  }
  #signUpDiv h1{
    font-size: 20px;
  }

  #ORWordSignUp{
    margin-top: 5%;
    margin-bottom: 5%;
  }

}
@media only screen and (min-width: 850px) and (max-width: 992px) {
  .inputSignUp,#bottomSignUp,#passwordDiv{
    width: 90%;
  }
 
  .btn--facebook{
    width: 90%;
  }
  #signUpDiv h1{
    font-size: 20px;
  }

  #ORWordSignUp{
    margin-top: 5%;
    margin-bottom: 5%;
  }
}
@media only screen and (min-width: 1920px) and (max-width: 2560px){
  #LoginButton{
    margin-left: 17%;
  }
  #LoginButton,#signUpDiv{
    font-size: 18px;
  }
  
  
}
@media only screen and (min-width: 2560px) and (max-width: 3840px){
  #LoginButton{
    margin-left: 20%;
  }
  #LoginButton,#signUpDiv{
    font-size: 20px;
  }
  
  
}
@media only screen and (min-width: 3840px){
  #LoginButton{
    margin-left: 20%;
  }
  #LoginButton,#signUpDiv{
    font-size: 35px;
  }
  
}