.ContactUsDiv {
    margin-left: 10%;
    width: 80%;
    /* height: 300px; */
}

.ContactUsDiv a {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

#contactUsGE {
    float: left;
    width: 50%;
    padding-left: 15%;
    padding-top: 5%;
}

#contactUsSE {
    float: left;
    width: 50%;
    padding-left: 10%;
    padding-top: 2%;
}

.contactFormLabel{
    margin-top: 3%;
    margin-bottom: 5px;
    font-weight: bold;
}

#contactSubmitBtn{
    background-color: rgb(51, 67, 77);
    color: white;
    border-radius: 10px;
    padding: 6px 10px;
    text-align: center;
    margin-top: 2%;
    width: 20%;
    cursor: pointer;
}

.contactFormInput{
    width: 100%;
    color: black;
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.contactFormInputForm{
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 200px;
    resize: none;
}

.ContactUsLeft{
    float: left;
    width: 48%;
}

.ContactUsRight{
    float: right;
    width: 48%;
}

.addressDiv {
    border: 1px solid rgb(172, 172, 172);
    width: 80%;
    margin-left: 10%;
    border-radius: 10px;
    background-color: #FAFAFA;
    margin-bottom: 5%;
    margin-top: 2%;
}

#contactUsMap {
    width: 30%;
    float: right;
    margin-right: 15%;
    margin-top: 2%;
}

.contactUsBtn {
    margin-right: 10%;
    background-color: #33434D;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    border-radius: 10px;
    transition: all 0.3s ease-out;
    cursor: pointer;
}

#ContactTitle{
    margin-top: 2%;
    font-weight: bold;
    font-size: 30px;
}

#ContactSubtitle{
    margin-top: 1%;
    font-size: 25px;
}

@media screen and (max-width: 960px) {
    .ContactUsLeft,.ContactUsRight{
        float: none;
        width: 98%;
    }
    #contactSubmitBtn{
        width: 100%;
    }
    .contactFormLabel{
        font-size: 12px;
    }

}

/* @media only screen and (max-width: 412px) {

    .ContactUsDiv {
        font-size: 13px;
    }

    #contactUsMap {
        display: block;
        float: none;
        width: 100%;
        padding: 15px;
    }

    #contactUsGE {
        padding: 15px;
    }

    .contactUsBtn {
        padding: 8px 10px;
        margin-right: 0;
    }

    #ContactUsDiv h1 {
        font-size: 20px;
    }

    #contactUsGE h2,
    .addressDiv h2 {
        font-size: 16px;
    }

    #contactUsGE p,
    .addressDiv p {
        font-size: 14px;
    }
    .contactUsBtn{
        font-size: 10px;
    }
}

@media only screen and (min-width: 412px) and  (max-width: 540px) {
    #contactUsMap {
        display: block;
        float: none;
        width: 100%;
        padding: 15px;
    }

    #contactUsGE {
        padding: 15px;
    }

    .contactUsBtn {
        padding: 8px 10px;
        margin-right: 0;
    }

    #ContactUsDiv h1 {
        font-size: 20px;
    }

    #contactUsGE h2,
    .addressDiv h2 {
        font-size: 16px;
    }

    #contactUsGE p,
    .addressDiv p {
        font-size: 14px;
    }
}

@media only screen and (min-width: 540px) and (max-width: 768px) {
    #contactUsMap {
        display: block;
        float: none;
        width: 100%;
        padding: 15px;
    }

    #contactUsGE {
        padding: 15px;
    }

    .contactUsBtn {
        padding: 8px 10px;
        margin-right: 0;
    }

    #ContactUsDiv h1 {
        font-size: 20px;
    }

    #contactUsGE h2,
    .addressDiv h2 {
        font-size: 16px;
    }

    #contactUsGE p,
    .addressDiv p {
        font-size: 14px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 800px) {
    #contactUsMap {
        display: block;
        float: none;
        width: 100%;
        padding: 15px;
    }

    #contactUsGE {
        padding: 15px;
    }

    .contactUsBtn {
        padding: 8px 10px;
        margin-right: 0;
    }

    #ContactUsDiv h1 {
        font-size: 20px;
    }

    #contactUsGE h2,
    .addressDiv h2 {
        font-size: 16px;
    }

    #contactUsGE p,
    .addressDiv p {
        font-size: 14px;
    }
}

@media only screen and (min-width: 800px) and (max-width: 850px) {
    #contactUsMap {
        display: block;
        float: none;
        width: 100%;
        padding: 15px;
    }

    #contactUsGE {
        padding: 15px;
    }

    .contactUsBtn {
        padding: 8px 10px;
        margin-right: 0;
    }

    #ContactUsDiv h1 {
        font-size: 20px;
    }

    #contactUsGE h2,
    .addressDiv h2 {
        font-size: 16px;
    }

    #contactUsGE p,
    .addressDiv p {
        font-size: 14px;
    }
}

@media only screen and (min-width: 850px) and (max-width: 992px) {
    #contactUsMap {
        display: block;
        float: none;
        width: 100%;
        padding: 15px;
    }

    #contactUsGE {
        padding: 15px;
    }

    .contactUsBtn {
        padding: 8px 10px;
        margin-right: 0;
    }

    #ContactUsDiv h1 {
        /* font-size: 20px; */
    /* }

    #contactUsGE h2,
    .addressDiv h2 {
        /* font-size: 16px; */
    


        /* font-size: 14px; */
   