#referIcon{
    font-size: 20px;
}

.hidden-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FAFAFA;
    padding: 8px;
    font-weight: bold;
    opacity: 1;
    transition: opacity 0.3s; /* Add a transition for opacity */
  }
  
  .hidden-message.show {
    opacity: 1;
  }
  
#claimRewardsDiv{
  padding: 8px;
}

.claimRewardsOption{
  padding: 10px;
  border: 1px solid #cbcbcb;
  margin-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.claimRewardsOption:hover{
  background-color: #c4c4c4;
}
.claimRewardsOptionHighlighted{
  background-color: #c4c4c4;
  margin-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px;
}
.NotAvailableOption{
  background-color: #a0a0a0;
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 10px;
}