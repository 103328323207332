#heartButton{
    border: none;
    cursor: pointer;
    background: none;
    /*  padding-bottom: 10%; */
    font-size: 10px;
    color: black;
    padding-right: 5px;
}

#heartSection{
    float:right;
    margin-right: 5%;
    width: 10%;
}
#eyeball_pendingChats{
    float:left;
    margin-left: 5%;
    width: 80%;
}
#residentialEyeball{
    position: absolute; 
    bottom: 0;

}

#residentialHeart{
    position: absolute;
    width: auto;
    bottom: 0;
    right: 0;
    margin-right: 5px;

}



