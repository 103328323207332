.accountTier{
    float: left;
    width: 32%;
    height: 200px;
    border-radius: 10px;
}

.accountTierLine{
    float: left;
    width: 33%;
    margin-right: 1%;
    height: 200px;
    border-radius: 10px;
}

.accountTierDetails{
    margin-top: 1%;
}

.accountTierPricing{
    margin-top: 10%;
}

.pricingAccount{
    background-color: #8AD7CB;
    width: fit-content;
    padding: 1% 1% 1% 1%;
    border-radius: 10px;
    margin-bottom: 1%;
}

.postalCodeInput{
    margin-top: 0.5%;
    margin-bottom: 0.5%;
}

.selectedChooseProperty{
    background-color: #c1c1c1;
}

.notSelectedChooseProperty:hover{
    background-color: #c1c1c1;
}