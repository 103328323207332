.Selected{
    background-color: #C4E5E0;
    border-radius: 10px;
    padding: 8px 8px;
    cursor: pointer;
}

.available{
    padding: 8px 8px;
    cursor: pointer;
}

.unavailable{
    background-color: #5d5d5d;
    border-radius: 10px;
    padding: 8px 8px;
    color: #ccc;
}