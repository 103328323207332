.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
  
  .top-row {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f1f1f1;
  }
  
  .bottom-row {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #ccc;
  }
  
  .component {
    flex: 1;
    height: 100%;
    width: 100%;
    border: 1px solid black;
    box-sizing: border-box;
    padding: 20px;
    background-color: white;
  }

  #aboutUsLeft{
    float: left;
    width: 50%;
    line-height: 1.5;
    text-align: justify;
    padding-left: 10%;
  }

  #aboutUsLeft p{
    color: grey;
    padding-top: 16px;
  }

  #aboutUsRight{
    float: right;
    width: 50%;
  }

  #slogan{
    color:#33434D;
    padding-left: 10%;
    font-size: 18px;
  }

  #aboutUsLeftRow2{
    float: left;
    width: 40%;
    margin-left: 10%;
  }

  #aboutUsRightRow2{
    float: right;
    width: 40%;
    line-height: 1.5;
    margin-right: 10%;
    text-align: justify;
    padding-top: 7%;
  }

  #aboutUsRightRow2 p{
    color: grey;
    padding-top: 16px;
  }

  .valueRow{
    display: flex;
    justify-content: center;
    margin-top: 2%;
  }

  .eachValue{
    display: flex;
    padding: 20px 20px;
    border-radius: 10px;
    height: 100px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: black;
  }

  .t_c_title{
    color: #33434D;
    font-size: 20px;
    font-weight: bold;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .t_cDiv{
    width: 90%;
    margin-left: 5%;
  }
  .t_c_date{
    color: #33434D;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 2%;
  }

  .t_c_text{
    color:grey;
    text-align: justify;
    line-height: 0.8cm;
  }

  .t_c_header{
    font-weight: bold;
    font-size: 17px;
    margin-top: 1%;
    margin-bottom: 1%;
  }

  #SecondPointerDiv{
    width: 90%;
    margin-left: 5%;
    margin-top: 1%;
    
  }

  #careerParentDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-left: 10%;
  }

  #careerChildDiv{
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  #careerChildDivSub{
    height: 150px;
  }
  #careerChildDivTitle{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 2%;
    padding: 15px;
  }

  #careerChildDivDescription{
    width: 50%;
    margin-left: 25%;
    color: grey;
  }

  #careerTitle{
    font-weight: bold;
    font-size: 35px;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  #careerDescription{
    color: grey;
    width: 50%;
    margin-bottom: 1%;
  }

  #careerJoinUsBtn{
    text-decoration: none;
    background-color: #33434D;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    border-radius: 10px;
    transition: all 0.3s ease-out;
    cursor: pointer;
  }

  #careerDescriptionBenefit{
    font-size: 13px;
    background-color: #C4E5E0;
    width: fit-content;
    padding: 5px 5px;
    border-radius: 10px;
    margin-bottom: 1%;

  }

  #careerTitleBenefit{
    font-size: 27px;
    font-weight: bold;
  }

  #aboutUsRowOne{
    margin-top: 2%;
  }

  #aboutUsRowTwo{
    margin-top: 2%;
    background-color: #FAFAFA;
  }
  #aboutUsFAQ{
    margin-top: 2%;
  }
  #aboutUsLeftRow2 img{
    width: 60%;
    height: 450px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }
  #howItWorkLeft{
    float: left;
    width: 50%;
  }
 
  #howItWorkRight{
    float: right;
    width: 50%;
  }


  #howItWorkImg{
    border-radius: 5%;
    height: 100%;
    cursor: pointer;
  }


.flip-box {
  background-color: transparent;
  height: 200vh;
  perspective: 1000px;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transform: rotateY(0deg); /* Initial rotation */
}

.flip-box.flipped .flip-box-inner {
  transform: rotateY(180deg); /* Flipped rotation */
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.indivRoleSection{
  display: flex;
  flex-direction: row;
}

.indivRoleSectionCol{
  width: 32%;
  margin-left: 1%;
  margin-right: 1%;
}

.indivRoleSectionColSpecial{
  width: 32%;
}

.flip-box-back {
  /* Styles for the back side */
  transform: rotateY(180deg); /* Initial rotation */
}

  @media only screen and (max-width: 410px) {
    .indivRoleSection{
      flex-direction: column;
      margin: 2%;
    }
    .indivRoleSectionCol,.indivRoleSectionColSpecial{
      width: 98%;
      margin: 1%;
    }
    .indivRoleSectionColSpecial{
      width: 98%;
      margin: 1%;
    }
    #howItWorkLeft{
      float: none;
      width: 100%;
    }
   
    #howItWorkRight{
      float: none;
      width: 100%;
    }
    #howItWorkImg{
      margin-left: 25%;
      width: 50%;
      
    }
    #careerChildDivSub{
      height: 220px;
  }
    #aboutUsLeft{
      padding: 15px;
      float: none;
      width: 100%;
    }
    #aboutUsRight{
      display: none;
    }
    #aboutUsLeftRow2{
      display: none;
    }
    #aboutUsRightRow2{
      width: 100%;
      float: none;
      padding: 15px;
    }
    #careerChildDivDescription{
      width: 100%;
      margin: 0;
    }
    #careerDescription{
      width: 90%;
    }
    #careerTitle{
      font-size: 20px;
    }
    #aboutUsLeft h2, #aboutUsRightRow2 h2, #aboutUsFAQ h2,#careerTitleBenefit{
      font-size: 18px;
    }
    #aboutUsLeft p, #aboutUsRightRow2 p, #careerChildDivTitle{
      font-size: 14px;
    }
    #careerChildDivDescription{
      font-size: 12px;
    }
    #careerDescriptionBenefit{
      font-size: 11px;
    }
  }

  @media only screen and (min-width: 410px) and (max-width: 540px) {
    .indivRoleSection{
      flex-direction: column;
      margin: 2%;
    }
    .indivRoleSectionCol{
      width: 98%;
      margin: 1%;
    }
    .indivRoleSectionColSpecial{
      width: 98%;
      margin: 1%;
    }
    #aboutUsLeft{
      padding: 15px;
      float: none;
      width: 100%;
    }
    #aboutUsRight{
      display: none;
    }
    #aboutUsLeftRow2{
      display: none;
    }
    #aboutUsRightRow2{
      width: 100%;
      float: none;
      padding: 15px;
    }
    #careerChildDivDescription{
      width: 100%;
      margin: 0;
    }
    #careerDescription{
      width: 90%;
    }
    #careerTitle{
      font-size: 20px;
    }
    #aboutUsLeft h2, #aboutUsRightRow2 h2, #aboutUsFAQ h2,#careerTitleBenefit{
      font-size: 18px;
    }
    #aboutUsLeft p, #aboutUsRightRow2 p, #careerChildDivTitle{
      font-size: 14px;
    }
    #careerChildDivDescription{
      font-size: 12px;
    }
    #careerDescriptionBenefit{
      font-size: 11px;
    }
  }
  @media only screen and (min-width: 540px) and (max-width: 768px) {
    .indivRoleSection{
      flex-direction: column;
      margin: 2%;
    }
    .indivRoleSectionCol,.indivRoleSectionColSpecial{
      width: 98%;
      margin: 1%;
    }
    #aboutUsLeft{
      padding: 15px;
      float: none;
      width: 100%;
    }
    #aboutUsRight{
      display: none;
    }
    #aboutUsLeftRow2{
      display: none;
    }
    #aboutUsRightRow2{
      width: 100%;
      float: none;
      padding: 15px;
    }
    #careerChildDivDescription{
      width: 100%;
      margin: 0;
    }
    #careerDescription{
      width: 90%;
    }
    #careerTitle{
      font-size: 20px;
    }
    #aboutUsLeft h2, #aboutUsRightRow2 h2, #aboutUsFAQ h2,#careerTitleBenefit{
      font-size: 18px;
    }
    #aboutUsLeft p, #aboutUsRightRow2 p, #careerChildDivTitle{
      font-size: 14px;
    }
    #careerChildDivDescription{
      font-size: 12px;
    }
    #careerDescriptionBenefit{
      font-size: 11px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 800px) {
    .indivRoleSection{
      flex-direction: column;
      margin: 2%;
    }
    .indivRoleSectionCol{
      width: 98%;
      margin: 1%;
    }
    #aboutUsLeft{
      padding: 15px;
      float: none;
      width: 100%;
    }
    #aboutUsRight{
      display: none;
    }
    #aboutUsLeftRow2{
      display: none;
    }
    #aboutUsRightRow2{
      width: 100%;
      float: none;
      padding: 15px;
    }
    #careerChildDivDescription{
      width: 100%;
      margin: 0;
    }
    #careerDescription{
      width: 90%;
    }
    #careerTitle{
      font-size: 20px;
    }
    #aboutUsLeft h2, #aboutUsRightRow2 h2, #aboutUsFAQ h2,#careerTitleBenefit{
      font-size: 18px;
    }
    #aboutUsLeft p, #aboutUsRightRow2 p, #careerChildDivTitle{
      font-size: 14px;
    }
    #careerChildDivDescription{
      font-size: 12px;
    }
    #careerDescriptionBenefit{
      font-size: 11px;
    }
  }
  @media only screen and (min-width: 800px) and (max-width: 850px) {
    .indivRoleSection{
      flex-direction: column;
      margin: 2%;
    }
    .indivRoleSectionCol{
      width: 98%;
      margin: 1%;
    }
    #aboutUsLeft{
      padding: 15px;
      float: none;
      width: 100%;
    }
    #aboutUsRight{
      display: none;
    }
    #aboutUsLeftRow2{
      display: none;
    }
    #aboutUsRightRow2{
      width: 100%;
      float: none;
      padding: 15px;
    }
    #careerChildDivDescription{
      width: 100%;
      margin: 0;
    }
    #careerDescription{
      width: 90%;
    }
    #careerTitle{
      font-size: 20px;
    }
    #aboutUsLeft h2, #aboutUsRightRow2 h2, #aboutUsFAQ h2,#careerTitleBenefit{
      font-size: 18px;
    }
    #aboutUsLeft p, #aboutUsRightRow2 p, #careerChildDivTitle{
      font-size: 14px;
    }
    #careerChildDivDescription{
      font-size: 12px;
    }
    #careerDescriptionBenefit{
      font-size: 11px;
    }
  }
  @media only screen and (min-width: 850px) and (max-width: 992px) {
    .indivRoleSection{
      flex-direction: column;
      margin: 2%;
    }
    .indivRoleSectionCol{
      width: 98%;
      margin: 1%;
    }
    #aboutUsLeft{
      padding: 15px;
      float: none;
      width: 100%;
    }
    #aboutUsRight{
      display: none;
    }
    #aboutUsLeftRow2{
      display: none;
    }
    #aboutUsRightRow2{
      width: 100%;
      float: none;
      padding: 15px;
    }
    #careerChildDivDescription{
      width: 100%;
      margin: 0;
    }
    #careerDescription{
      width: 90%;
    }
    #careerTitle{
      font-size: 20px;
    }
    #aboutUsLeft h2, #aboutUsRightRow2 h2, #aboutUsFAQ h2,#careerTitleBenefit{
      font-size: 18px;
    }
    #aboutUsLeft p, #aboutUsRightRow2 p, #careerChildDivTitle{
      font-size: 14px;
    }
    #careerChildDivDescription{
      font-size: 12px;
    }
    #careerDescriptionBenefit{
      font-size: 11px;
    }
  }

  @media only screen and (min-width: 2560px) and (max-width: 3840px){
    #aboutUsLeft,#aboutUsRightRow2,#aboutUsFAQ,#careerDescription,#careerTitleBenefit,#careerChildDivTitle,.t_c_title{
      /* font-size: 30px; */
    }
    .t_c_header{
      /* font-size: 25px; */
    }
    #aboutUsLeftRow2 img{
      height: 700px;
    }
    #careerJoinUsBtn,#careerDescriptionBenefit,#careerChildDivDescription,.t_c_text{
      /* font-size: 20px; */
    }
    
    
  }

  @media only screen and (min-width: 3840px){
    #aboutUsLeft,#aboutUsRightRow2,#aboutUsFAQ,#careerDescription,#careerTitleBenefit,#careerChildDivTitle,.t_c_title{
      font-size: 40px;
    }
    .t_c_header{
      font-size: 35px;
    }
    #aboutUsLeftRow2 img{
      height: 1000px;
    }
    #careerJoinUsBtn,#careerDescriptionBenefit,#careerChildDivDescription,.t_c_text{
      font-size: 30px;
    }
  }
  