/* Float four columns side by side */
/* .colCard {
    float: left;
    min-height: 320px;
    padding: 0 10px;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    border-radius: 10px;
    background-color: #fff;
  }

  .colCard a{
    color: black;
    text-decoration: none;
    cursor: pointer;
  } */
  #favouriteHeart{
    color:#ff3040;
  }
  /* Remove extra left and right margins, due to padding */
  .searchBtn {
    border: 1px solid black;
    width: 40%;
    height: 50px;
    /* margin-left: 30%; */
    margin-top: 3%;
    border-radius: 10px;
    background-color: white;
    cursor: pointer;
  }
  
  .searchBtn i {
    float: right;
    margin-right: 3%;
  }
  
  .rowCard {
    /* margin: 3% -5px; */
    margin-top: 3%;
    min-height: 700px;
    width: 90%;
    padding-left: 10%;
  }
  
  .Card {
    float: left;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    transition: 0.3s;
    width: 23%;
    height: 425px;
    /* aspect-ratio: 4/5; */
    margin: 1%;
    /* background-color: white; */
  
  }
 
  .Card_SecondOverall{
    display:flex;
    flex-direction: row;
    padding-bottom: 5px;
    padding-top: 5px;
    border-bottom: 1px solid #c1c1c1;
    height: 25%;
    
  }
  .CardResidential{
    float: left;
    transition: 0.3s;
    width: 23%;
    height: 320px;
    /* aspect-ratio: 4/5; */
    margin: 1%;
    border: 5px solid #33434D;
    border-radius: 10px;
    position: relative;
    padding: 5px;
  }
  
  .CardPurchase{
    float: left;
    transition: 0.3s;
    width: 23%;
    height: 320px;
    /* aspect-ratio: 4/5; */
    margin: 1%;
    border: 5px solid #33434D;
    border-radius: 10px;
    position: relative;
    padding: 5px;
  }

  .CardResidentialSales{
    float: left;
    transition: 0.3s;
    width: 23%;
    height: 320px;
    /* aspect-ratio: 4/5; */
    margin: 1%;
    border: 5px solid #33434D;
    border-radius: 10px;
    position: relative;
    padding: 5px;
  }
  
  .Card a, .CardResidential a, .CardPurchase a ,.CardResidentialSales a{
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* .Card:hover {
    box-shadow: 0 8px 16px 0 rgba(49, 49, 49, 0.2);
  } */
  
  #Card_image {
    border-top-left-radius: 10px; 
    border-top-right-radius: 10px; 
    width: 100%;
    height: 250px;
    object-fit:cover;
  }
  
  #preferredBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .carousel .thumbs-wrapper{
    display: none;
  }
  
  
  
  #gradientBackground {
    position: absolute;
    border-radius: 0 0 10px 10px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  }
  
  #informationGradientBackground {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  #informationGradientBackgroundLeft {
    float: left;
    width: 88%;
  }
  
  #informationGradientBackgroundLeft i {
    padding-left: 5px;
  }
  
  #informationGradientBackgroundRight {
    float: right;
    /* width: 5%; */
  }
  
  #Card_overall {
    padding-top: 5px;
    padding-bottom: 5px;
    /* border-bottom: 1px solid #c1c1c1; */
    /* padding-left: 10px; */
  }
  

  
  #cardResidentialUserInfo{
    float: right;
    font-size: 15px;
    width: 77%;
  }
  #humanIcon{
    font-size: 18px;
    margin-right: 5px;
  }
  #childIcon{
    font-size: 14px;
    margin-right: 5px;
  }
  
  #residentialHuman{
    float: left;
    display: flex;
    padding-top: 5px;
    align-items: flex-end;
  }
  
  #cardResidentialUserImage{
    max-width: 18%;
    height: 50px;
    border-radius: 10px;
    float: left;
    margin-right: 5%;
  }
  
  #commericalRentMidPart{
    padding-left: 5px;
    padding-top: 5px;
  }
  #residentialPreferred{
    /* margin-bottom: 2%; */
    margin-top: 2%;
    /* font-size: 14px; */
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
    /* text-align: center; */
    background-color: #33434D;
    color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
  }
  .CardContainer{
    height: 46%;
  }

  
  .purchase_rent {
    padding: 5px 40px;
    border: none;
    font-size: 14px;
    color: white;
    background-color: transparent;
    cursor: pointer;
    font-weight: bold;
  }
  
  .purchase_rent.active{
    background-color: white;
    color: black;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* Green color */
  }
  
  .advertiseCard{
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* aspect-ratio: 4/5; */
    /* height: 385px; */
    border-radius: 10px;
  }

 
  .advertiseCardAccount{
    max-width: 100%;
    height: 340px;
    border-radius: 10px;
  }
  .CardAdvertiseDivAccount{
    border: 1px solid #ccc;
    margin: 1%;
    border-radius: 10px;
    flex-direction: column;
  }
  .CardAdvertiseDivCommercialRent{
    float: left;
    transition: 0.3s;
    width: 23%;
    height: 425px;
    /* aspect-ratio: 4/5; */
    position: relative;
    padding: 5px;
    justify-content: center;
    display: flex;
    cursor: pointer;
    margin: 1%;
  }
  .CardAdvertiseDivCommercialSale{
    float: left;
    transition: 0.3s;
    width: 23%;
    height: 320px;
    /* aspect-ratio: 4/5; */
    position: relative;
    padding: 5px;
    justify-content: center;
    display: flex;
    cursor: pointer;
    margin: 1%;
  }
  .CardAdvertiseDivResidentialRent{
    float: left;
    transition: 0.3s;
    width: 23%;
    height: 320px;
    /* aspect-ratio: 4/5; */
    position: relative;
    padding: 5px;
    justify-content: center;
    display: flex;
    cursor: pointer;
    margin: 1%;
  }
  .CardAdvertiseDivResidentialSale{
    float: left;
    transition: 0.3s;
    width: 23%;
    height: 320px;
    /* aspect-ratio: 4/5; */
    position: relative;
    padding: 5px;
    justify-content: center;
    display: flex;
    cursor: pointer;
    margin: 1%;
  }
  .CardAdvertiseDivAccount{
    float: left;
    transition: 0.3s;
    width: 23%;
    /* height: 400px; */
    aspect-ratio: 4/5;
    position: relative;
    padding: 5px;
    justify-content: center;
    display: flex;
    cursor: pointer;
    margin: 1%;
  }
  .purchaseOption{
    border-bottom: 1px solid #ccc;
    padding-top: 8px;
    padding-bottom: 8px;
   
  }

  #Card_price{
    font-size: 16px;
    font-weight: bold;
   
  }

  #monthlyRentalBudget{
    font-size: 14px;
  }
  
  
  #Card_type {
  
   

  }
  
  #Card_district {
    /* padding-top: 5%; */
    /* font-size: 18px; */
    font-weight: bold;
    color: #4d4c4c;
    padding-top: 10px;
  
  }
  
  #Card_preferred {
    padding: 3%;
    background-color: #33434D;
    border-radius: 10px;
    color: white;
    font-size: 13px;
    width: fit-content;
    margin-top: 3%;
    margin-left: 3%;
  }
  
  .searchBar,
  .searchBarMobile {
    position: relative;
    margin-left: 10%;
    margin-top: 2%;
    width: 80%;
    border-radius: 10px;
    padding: 10px 10px;
    background-color: #33434dbf;
    border: none;
  }
  
  #searchBar {
    /* align-items: center; */
    justify-content: space-around;
    display: flex;
    flex-direction: column;
  }
  
  #residentialPurchaseInfo, #residentialPurchaseInfoAccount{
    height: 25%;
  }

  #residentialRentInfo, #residentialRentInfoAccount{
    height: 25%;
  }
  #commercialSaleInfo, #commercialSaleInfoAccount{
    height: 20%;
  }

  .searchBarMobile {
    display: none;
  }
  
  .btn--search {
    background-color: #33434D;
    color: white;
    padding: 8px 20px;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .btn--filter {
    /* border: 1px solid #808080;
    color: #808080;
    padding: 8px 20px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #FAFAFA; */
  }
  
  #moreFilterProduct {
    position: absolute;
    top:100%;
    left:0;
    width: 100%;
    background-color: #FAFAFA;
    z-index: 1;
    /* margin-top: 5%; */
    padding: 1%;
    border-radius: 10px;
  }
  
  
  /* Clear floats after the columns */
  /* .rowCard:after {
      content: "";
      display: table;
      clear: both;
    } */
  
  /* .card{
      width: 100%;
      height:400px;
  }
  .card-img-top{
    width: 300px;
    height: 200px;
    justify-content: center;
    display: flex;
    margin: auto;
  } */
  
  /* #cardBoostItem{
    width: 100%;
    border: solid 3px red;
    height:400px
  }
  .descriptionOfListing{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  } */
  
  #popover-contained {
    border-radius: 10px;
    margin-left: -10%;
    width: 50%;
    background: rgba(255, 255, 255, 0.936);
    z-index: 1;
  }
  

  
  #dropdownFilterMin{
    float: right;
    color: black;
    width: 10%;
    margin-right: 7.5%;
    margin-top: 1%;
    padding: 3px 8px;
    border-radius: 10px;
  }
  
  
  #Buy h4,
  #Buy p {
    margin-left: 2%;
    margin-top: 2%;
  }
  
  .BuyInput {
    width: 96%;
    margin-left: 2%;
    margin-top: 1%;
    min-height: 30px;
    border-radius: 10px;
    border: 1px solid grey;
    padding-left: 10px;
  }
  
  .propertyType button {
    margin: 1% 2%;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
  
    cursor: pointer;
    border: none;
  }
  
  .propertyType .active,
  #bedroomButton .active {
    background-color: #33434D;
    color: white;
  }
  
  #priceLeft {
    float: left;
    width: 50%;
  }
  
  #priceLeft h5 {
    margin-top: 2%;
    margin-left: 4%;
  }
  
  #priceLeft input {
    width: 90%;
    margin-left: 3%;
    margin-top: 1%;
    min-height: 30px;
    border-radius: 10px;
    border: 1px solid grey;
    padding-left: 10px;
  }
  
  #priceRight {
    float: right;
    width: 50%;
  }
  
  #priceRight h5 {
    margin-top: 2%;
    margin-left: 4%;
  }
  
  #priceRight input {
    width: 90%;
    margin-left: 3%;
    margin-top: 1%;
    min-height: 30px;
    border-radius: 10px;
    border: 1px solid grey;
    padding-left: 10px;
  }
  
  #Buy input,
  #Rent input {
    margin-top: 2%;
    margin-left: 2%;
  }
  
  #bedroomButton {
    margin-left: 2%;
    margin-top: 2%;
  }
  
  #bedroomButton button {
    margin-left: 5%;
    padding: 8px 15px 8px 15px;
    border: 1px solid black;
    border-radius: 10px;
    background-color: white;
    cursor: pointer;
  }

  .CardAdvertiseDivCommercialRentAdv{
    transition: 0.3s;
    width: 100%;
    height: 425px;
    /* aspect-ratio: 4/5; */
    position: relative;
    /* padding: 5px; */
    justify-content: center;
    display: flex;
    cursor: pointer;
    /* margin: 1%; */
  }

  .CardContainerAdv{
    min-height: 10%;
  }
  
  #bedroomButton button:hover {
    background-color: rgba(255, 0, 0, 0.314);
  }
  
  #magnifyingIcon{
    font-size: 25px;
    align-items: center;
    display: flex;
  }
  
  #ClearAll {
    border: none;
    color: red;
    cursor: pointer;
    background-color: white;
    margin: 5% 2%;
  }
  
  #Search {
    float: right;
    margin-right: 5%;
  }
  
  #filterClear{
    border: none;
    background-color: transparent;
    color:white;
    font-weight: bold;
    cursor: pointer;
  }
  
  #filterBar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-top: 8px;
    color: white;
  }
  
  #clearBtnDiv{
    padding-right: 1%;
    padding-top: 1%;
    text-align: right;
  }

  .picture-container {
    overflow-x: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .picture-scroll {
    display: flex;
    flex-wrap: nowrap;
  }
  
  .picture-scroll img {
    max-width: 100px;
    max-height: 100px;
    margin-right: 10px;
  }
  
  
  
  @media only screen and (max-width: 290px) {
    .CardAdvertiseDivCommercialRent{
      width: 98%;
      aspect-ratio: 4/5;
      height: auto;
    }
    .CardAdvertiseDivCommercialSale{
      width: 98%;
      height: auto;
      aspect-ratio: 4/5;
    }
    .CardAdvertiseDivResidentialRent{
      width: 98%;
      aspect-ratio: 4/5;
      height: auto;
    }
    .CardAdvertiseDivResidentialSale{
      width: 98%;
      aspect-ratio: 4/5;
      height: auto;
    }
  
    #clearBtnDiv{
      padding-top: 3%;
    }
    #dropdownFilterMin{
      width: 40%;
    }
    .rowCard{
      padding-left: 5%;
      width: 95%;
      margin-top: 10%;
      
    }
  
    .Card_SecondOverall{
      height: 25%;
    }
    #filterBar{
      width: 100%;
    }
    .Card {
      /* margin: 1%; */
      margin-top: 5%;
      margin-bottom: 5%;
      font-size: 14px;
      width: 98%;
      /* height: 290px; */
    }
  
    .CardPurchase{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 98%;
      font-size: 14px;
      /* height: 320px; */
    }
    .CardResidentialSales{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 98%;
      font-size: 14px;
      /* height: 320px; */
    }
  
  
    .CardResidential{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 98%;
      font-size: 14px;
      /* height: 320px; */
    }
  
    .CardAdvertiseDiv, .CardAdvertiseDivAccount{
      width: 98%;
    }
  
    #heartButton i{
      font-size: medium;
    }
    #Card_district,#Card_type,#Card_preferred  {
      font-size: 14px;
    }
  
    #Card_image {
      width: 100%;
    }
  
    #gradientBackground {
      height: 25px;
    }
  
  
    .searchBar {
      margin-left: 0%;
     width: 100%;
    }
  
    /* .searchBarMobile {
      padding: 8px 10px;
      font-size: 12px;
      display: block;
    } */
  
    .searchbarIndivColBtn {
      display: block;
      width: auto;
    }
  
    .btn--filter {
      float: right;
    }
  
    .btn--search {
      float: right;
    }
  
    .propertyType .active,.propertyType button,.searchbarIndivCol input, .searchbarIndivCol select,.btn--search,.btn--filter, #heartButton i,#magnifyingIcon,.purchase_rent {
      font-size: 10px;
    }
    #informationGradientBackgroundLeft,#filterText{
      font-size: 10px;
    }
  
  
  
  }
  @media only screen and (min-width: 290px) and (max-width: 325px) {
    #clearBtnDiv{
      padding-top: 3%;
    }
    #dropdownFilterMin{
      width: 40%;
    }
    .rowCard{
      padding-left: 5%;
      width: 95%;
      margin-top: 10%;
      
    }
  
    #filterBar{
      width: 100%;
    }
    .Card {
      /* margin: 1%; */
      margin-top: 5%;
      margin-bottom: 5%;
      font-size: 14px;
      width: 98%;
      /* height: 330px; */
    }
  
    .CardPurchase{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 98%;
      font-size: 14px;
      height: 260px;
    }
  
  
    .CardAdvertiseDivCommercialRent{
      width: 98%;
      height: auto;
      aspect-ratio: 4/5;
    }
    .CardAdvertiseDivCommercialSale{
      width: 98%;
      aspect-ratio: 4/5;
      height: auto;
    }
    .CardAdvertiseDivResidentialRent{
      width: 98%;
      height: auto;
      aspect-ratio: 4/5;
    }
    .CardAdvertiseDivResidentialSale{
      width: 98%;
      height: auto;
      aspect-ratio: 4/5;
    }
   
  
    #commercialSaleInfo, #commercialSaleInfoAccount{
      height: 25%;
    }
    #residentialPurchaseInfo, #residentialPurchaseInfoAccount{
      height: 30%;
    }
    #residentialRentInfo, #residentialRentInfoAccount{
      height: 30%;
    }

    .Card_SecondOverall{
      height: 25%;
    }
    .CardResidentialSales{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 98%;
      font-size: 14px;
      height: 200px;
    }
  
    .CardResidential{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 98%;
      font-size: 14px;
      height: 200px;
    }
  
    .CardAdvertiseDiv, .CardAdvertiseDivAccount{
      width: 98%;
    }
  
    #heartButton i{
      font-size: medium;
    }
    #Card_district,#Card_type,#Card_preferred  {
      font-size: 14px;
    }
  
    #Card_image {
      width: 100%;
    }
  
    #gradientBackground {
      height: 25px;
    }
  
  
    .searchBar {
      margin-left: 0%;
     width: 100%;
    }
  
    /* .searchBarMobile {
      padding: 8px 10px;
      font-size: 12px;
      display: block;
    } */
  
    .searchbarIndivColBtn {
      display: block;
      width: auto;
    }
  
    .btn--filter {
      float: right;
    }
  
    .btn--search {
      float: right;
    }
  
    .propertyType .active,.propertyType button,.searchbarIndivCol input, .searchbarIndivCol select,.btn--search,.btn--filter, #heartButton i,#magnifyingIcon,.purchase_rent {
      font-size: 12px;
    }
    #informationGradientBackgroundLeft,#filterText{
      font-size: 10px;
    }
  
  }
  @media only screen and (min-width: 325px) and (max-width: 360px) {
    #clearBtnDiv{
      padding-top: 3%;
    }
    #dropdownFilterMin{
      width: 40%;
    }
    .rowCard{
      padding-left: 5%;
      margin-top: 10%;
      width: 95%;
      
    }
  
    #filterBar{
      width: 100%;
    }
    .Card {
      /* margin: 1%; */
      margin-top: 5%;
      margin-bottom: 5%;
      font-size: 14px;
      width: 98%;
      /* height: 330px; */
    }
  
    .CardPurchase{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 98%;
      font-size: 14px;
      /* height: 320px; */
    }
  
    .CardResidential{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 98%;
      font-size: 14px;
      /* height: 320px; */
    }
    .CardAdvertiseDivCommercialRent{
      width: 98%;
      aspect-ratio: 4/5;
      height: auto;
    }
    .CardAdvertiseDivCommercialSale{
      width: 98%;
      aspect-ratio: 4/5;
      height: auto;
    }
    .CardAdvertiseDivResidentialRent{
      width: 98%;
      height: auto;
      aspect-ratio: 4/5;
    }
    .CardAdvertiseDivResidentialSale{
      width: 98%;
      height: auto;
      aspect-ratio: 4/5;
    }
  
    .CardAdvertiseDiv, .CardAdvertiseDivAccount{
      width: 98%;
    }
  
    #heartButton i{
      font-size: medium;
    }
    #Card_district,#Card_type,#Card_preferred  {
      font-size: 14px;
    }
  
    #Card_image {
      width: 100%;
    }
  
    #gradientBackground {
      height: 25px;
    }
  
  
    .searchBar {
      margin-left: 0%;
     width: 100%;
    }
  
    /* .searchBarMobile {
      padding: 8px 10px;
      font-size: 12px;
      display: block;
    } */
  
    .searchbarIndivColBtn {
      display: block;
      width: auto;
    }
  
    .btn--filter {
      float: right;
    }
  
    .btn--search {
      float: right;
    }
  
    .propertyType .active,.propertyType button,.searchbarIndivCol input, .searchbarIndivCol select,.btn--search,.btn--filter, #heartButton i,#magnifyingIcon,.purchase_rent,#filterText {
      font-size: 12px;
    }
    #informationGradientBackgroundLeft{
      font-size: 10px;
    }
  
  
  }
  @media only screen and (min-width: 360px) and (max-width: 412px) {
    #Card_price{
      font-size: 14px;
    }
    #monthlyRentalBudget{
      font-size: 12px;
    }
    #clearBtnDiv{
      padding-top: 3%;
    }
    #dropdownFilterMin{
      width: 40%;
    }
    .rowCard{
      padding-left: 5%;
      width: 95%;
      margin-top: 10%;
    }
  
    #filterBar{
      width: 100%;
    }
    .Card {
      /* margin: 1%; */
      margin-top: 5%;
      margin-bottom: 5%;
      font-size: 14px;
      width:48%;
      /* height: 330px; */
    }
  
    .CardPurchase{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 98%;
      font-size: 14px;
      height: 300px;
    }

    .CardResidentialSales{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 98%;
      font-size: 14px;
      height: 280px;
    }
    .CardAdvertiseDivCommercialRent{
      width: 98%;
      height: auto;
      aspect-ratio: 4/5;
    }
    .CardAdvertiseDivCommercialSale{
      width: 98%;
      height: auto;
      aspect-ratio: 4/5;
    }
    .CardAdvertiseDivResidentialRent{
      width: 98%;
      height: auto;
      aspect-ratio: 4/5;
    }
    .CardAdvertiseDivResidentialSale{
      width: 98%;
      height: auto;
      aspect-ratio: 4/5;
    }

    .Card_SecondOverall{
      height: 25%;
    }
    #commercialSaleInfo, #commercialSaleInfoAccount{
      height: 25%;
    }

    #residentialRentInfo, #residentialRentInfoAccount{
      height: 30%;
    }
    #residentialPurchaseInfo, #residentialPurchaseInfoAccount{
      height: 30%;
    }
  
    .CardResidential{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 98%;
      font-size: 14px;
      height: 280px;
    }
  
    .CardAdvertiseDiv, .CardAdvertiseDivAccount{
      width: 98%;
    }
  
    #heartButton i{
      font-size: medium;
    }
    #Card_district,#Card_type,#Card_preferred  {
      font-size: 14px;
    }
  
    #Card_image {
      width: 100%;
    }
  
    #gradientBackground {
      height: 25px;
    }
  
  
    .searchBar {
      margin-left: 0%;
     width: 100%;
    }
  
    /* .searchBarMobile {
      padding: 8px 10px;
      font-size: 12px;
      display: block;
    } */
  
    .searchbarIndivColBtn {
      display: block;
      width: auto;
    }
  
    .btn--filter {
      float: right;
    }
  
    .btn--search {
      float: right;
    }
  
    .propertyType .active,.propertyType button,.searchbarIndivCol input, .searchbarIndivCol select,.btn--search,.btn--filter, #heartButton i,#magnifyingIcon,.purchase_rent,#filterText {
      font-size: 12px;
    }
    #informationGradientBackgroundLeft{
      font-size: 10px;
    }
    .CardAdvertiseDivAccount{
      /* height: 470px; */
      font-size: 12px;
    }
  
  } 
  @media only screen and (min-width: 412px) and (max-width: 540px) {
    #clearBtnDiv{
      padding-top: 3%;
    }
    #dropdownFilterMin{
      width: 40%;
    }
    
    .rowCard{
      padding-left: 5%;
      width: 95%;
      margin-top: 10%;
    }
    .Card {
      /* margin: 1%; */
      margin-top: 5%;
      margin-bottom: 5%;
      font-size: 14px;
      width: 48%;
      /* height: 380px; */
    }
    .CardPurchase{
      margin-top: 5%;
      margin-bottom: 5%;
      font-size: 14px;
      width: 98%;
      height: 320px;
    }

    #commercialSaleInfo, #commercialSaleInfoAccount{
      height: 25%;
    }
    #residentialPurchaseInfo, #residentialPurchaseInfoAccount{
      height: 30%;
    }
    #residentialRentInfo, #residentialRentInfoAccount{
      height: 30%;
    }
    .CardAdvertiseDivCommercialRent{
      width: 98%;
      height: auto;
      aspect-ratio: 4/5;
    }
    .CardAdvertiseDivCommercialSale{
      width: 98%;
      height: auto;
      aspect-ratio: 4/5;
    }
    .CardAdvertiseDivResidentialRent{
      width: 98%;
      height: auto;
      aspect-ratio: 4/5;
    }
    .CardAdvertiseDivResidentialSale{
      width: 98%;
      height: auto;
      aspect-ratio: 4/5;
    }
    .Card_SecondOverall{
      height: 25%;
    }
    .CardResidentialSales{
      margin-top: 5%;
      margin-bottom: 5%;
      font-size: 14px;
      width: 98%;
      height: 280px;
    }
    .CardResidential{
      margin-top: 5%;
      margin-bottom: 5%;
      font-size: 14px;
      width: 98%;
      height: 280px;
    }
  
    #heartButton i{
      font-size: medium;
    }
    #Card_district,#Card_type,#Card_preferred  {
      font-size: 14px;
    }
  
    #Card_image {
      width: 100%;
    }
  
    #gradientBackground {
      height: 30px;
    }
  
    .searchBar{
      width: 90%;
      margin-left: 5%;
    }
  
  
    .searchbarIndivColBtn {
      display: block;
      width: auto;
    }
  
    .btn--filter {
      float: right;
    }
  
    .btn--search {
      float: right;
    }
  
    .propertyType .active,.propertyType button,.searchbarIndivCol input, .searchbarIndivCol select,.btn--search,.btn--filter,#magnifyingIcon,.purchase_rent,#filterText {
      font-size: 12px;
    }
      
    .CardAdvertiseDiv, .CardAdvertiseDivAccount{
      width: 98%;
    }
    .CardAdvertiseDivAccount{
      /* height: 470px; */
      font-size: 12px;
    }
  
  
  }
  @media only screen and (min-width: 540px) and (max-width: 640px) {
    #clearBtnDiv{
      padding-top: 3%;
    }
    #dropdownFilterMin{
      width: 40%;
    }
    .rowCard{
      padding-left: 5%;
      width: 95%;
      margin-top: 10%;
    }
  
    .Card {
      margin-top: 5%;
      margin-bottom: 5%;
      width: 48%;
      /* height: 320px; */
    }
    .CardPurchase{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 48%;
      height: 320px;
    }
    .CardResidentialSales{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 48%;
      height: 280px;
    }
    .CardResidential{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 48%;
      height: 280px;
    }
   


    .CardAdvertiseDivCommercialRent, .CardAdvertiseDivCommercialSale, .CardAdvertiseDivResidentialRent ,.CardAdvertiseDivResidentialSale{
      width: 48%;
      margin-top: 5%;
      margin-bottom: 5%;
    }

    .CardAdvertiseDivCommercialSale{
      
      height: 0px;
    }
    .CardAdvertiseDivResidentialRent{
      height: 320px;
    }
    .CardAdvertiseDivResidentialSale{
      height: 320px;
      
    }
  
  
    #heartButton i{
      font-size: medium;
    }
    #Card_district,#Card_type,#Card_preferred  {
      font-size: 11px;
    }
  
    #Card_image {
      width: 100%;
    }
    #gradientBackground {
      height: 30px;
    }
  
    
  
  
  
    .searchbarIndivColBtn {
      display: block;
      width: auto;
    }
  
    .btn--filter {
      float: right;
    }
  
    .btn--search {
      float: right;
    }
  
    .propertyType .active,.propertyType button,.searchbarIndivCol input, .searchbarIndivCol select,.btn--search,.btn--filter,#magnifyingIcon,.purchase_rent,#filterText {
      font-size: 12px;
    }
      
    .CardAdvertiseDiv, .CardAdvertiseDivAccount{
      width: 98%;
    }
    .CardAdvertiseDivAccount{
      /* height: 200px; */
      font-size: 12px;
    }
  }
  @media only screen and (min-width: 640px) and (max-width: 712px) {
    #clearBtnDiv{
      padding-top: 3%;
    }
    #dropdownFilterMin{
      width: 40%;
    }
    .rowCard{
      padding-left: 5%;
      width: 95%;
      margin-top: 10%;
    }
  
    .Card {
      margin-top: 5%;
      margin-bottom: 5%;
      width: 48%;
      /* height: 320px; */
    }
    .CardPurchase{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 48%;
      /* height: 360px; */
    }
    .CardResidential{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 48%;
      /* height: 350px; */
    }
    
  
    .CardAdvertiseDivCommercialRent, .CardAdvertiseDivCommercialSale, .CardAdvertiseDivResidentialRent ,.CardAdvertiseDivResidentialSale{
      width: 48%;
    }
    #heartButton i{
      font-size: medium;
    }
    #Card_district,#Card_type,#Card_preferred  {
      font-size: 11px;
    }
  
    #Card_image {
      width: 100%;
    }
    #gradientBackground {
      height: 30px;
    }
  
  
  
  
    .searchbarIndivColBtn {
      display: block;
      width: auto;
    }
  
    .btn--filter {
      float: right;
    }
  
    .btn--search {
      float: right;
    }
  
    .propertyType .active,.propertyType button,.searchbarIndivCol input, .searchbarIndivCol select,.btn--search,.btn--filter,#filterText{
      font-size: 12px;
    }
      
    .CardAdvertiseDiv, .CardAdvertiseDivAccount{
      width: 98%;
    }
    .CardAdvertiseDivAccount{
      /* height: 470px; */
      font-size: 12px;
    }
  
  }
  @media only screen and (min-width: 712px) and (max-width: 768px) {
    #clearBtnDiv{
      padding-top: 3%;
    }
    #dropdownFilterMin{
      width: 40%;
    }
    .CardAdvertiseDivCommercialRent, .CardAdvertiseDivCommercialSale, .CardAdvertiseDivResidentialRent ,.CardAdvertiseDivResidentialSale{
      width: 48%;
      margin-top: 5%;
      margin-bottom: 5%;
    }
    .Card {
      margin-top: 5%;
      margin-bottom: 5%;
      width: 48%;
      /* height: 320px; */
    }
    .CardPurchase{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 48%;
      /* height: 360px; */
    }
    .CardResidentialSales{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 48%;
      /* height: 360px; */
    }
    .CardResidential{
      margin-top: 5%;
      margin-bottom: 5%;
      width: 48%;
      /* height: 350px; */
    }
  
    #gradientBackground {
      height: 30px;
    }
    #heartButton i{
      font-size: medium;
    }
    #Card_district,#Card_type,#Card_preferred  {
      font-size: 14px;
    }
  
    #Card_image {
      width: 100%;
    }
  
  
  
  
  
    .searchbarIndivColBtn {
      display: block;
      width: auto;
    }
  
    .btn--filter {
      float: right;
    }
  
    .btn--search {
      float: right;
    }
  
    .propertyType .active,.propertyType button,.searchbarIndivCol input, .searchbarIndivCol select,.btn--search,.btn--filter,#filterText{
      font-size: 12px;
    }
      
    .CardAdvertiseDiv, .CardAdvertiseDivAccount{
      width: 48%;
    }
    .CardAdvertiseDivAccount{
      /* height: 250px; */
      font-size: 12px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 800px) {
    #clearBtnDiv{
      padding-top: 3%;
    }
    #dropdownFilterMin{
      width: 40%;
    }
    .CardAdvertiseDivCommercialRent, .CardAdvertiseDivCommercialSale, .CardAdvertiseDivResidentialRent ,.CardAdvertiseDivResidentialSale{
      width: 47%;
      margin-top: 5%;
      margin-bottom: 5%;
    }
    .Card {
      /* margin: 1%; */
      /* margin-top: 5%; */
      /* margin-bottom: 5%; */
      font-size: 12px;
      width: 47%;
      /* height: 360px; */
      /* height: 240px; */
    }
    
    .CardPurchase{
      margin-top: 5%;
      margin-bottom: 5%;
      font-size: 12px;
      width: 47%; 
    }
    .CardResidential{
      margin-top: 5%;
      margin-bottom: 5%;
      font-size: 12px;
      width: 47%;
    }
  
    #heartButton i{
      font-size: medium;
    }
    #Card_district,#Card_type,#Card_preferred  {
      font-size: 11px;
    }
  
    #Card_image {
      width: 100%;
    }
  
    .searchbarIndivColBtn {
      display: block;
      width: auto;
    }
  
    .btn--filter {
      float: right;
    }
  
    .btn--search {
      float: right;
    }
  
    .propertyType .active,.propertyType button,.searchbarIndivCol input, .searchbarIndivCol select,.btn--search,.btn--filter,#filterText{
      font-size: 12px;
    }
      
    .CardAdvertiseDiv, .CardAdvertiseDivAccount{
      width: 47%;
    }
    .CardAdvertiseDivAccount{
      /* height: 250px; */
      font-size: 12px;
    }
  
  } 
  @media only screen and (min-width: 800px) and (max-width: 850px) {
    #clearBtnDiv{
      padding-top: 3%;
    }
    #dropdownFilterMin{
      width: 40%;
    }
    .CardAdvertiseDivCommercialRent, .CardAdvertiseDivCommercialSale, .CardAdvertiseDivResidentialRent ,.CardAdvertiseDivResidentialSale{
      width: 47%;
    }
    .Card {
      /* margin: 1%; */
      font-size: 14px;
      width: 47%;
      /* height: 360px; */
      /* height: 240px; */
    }
    
    .CardPurchase, .CardResidentialSales{
      font-size: 14px;
      width: 47%; 
    }
    .CardResidential{
      font-size: 14px;
      width: 47%;
    }
  
    #heartButton i{
      font-size: medium;
    }
    #Card_district,#Card_type,#Card_preferred  {
      font-size: 14px;
    }
  
    #Card_image {
      width: 100%;
    }
  
  
  
  
  
  
    .searchbarIndivColBtn {
      display: block;
      width: auto;
    }
  
    .btn--filter {
      float: right;
    }
  
    .btn--search {
      float: right;
    }
  
    .propertyType .active,.propertyType button,.searchbarIndivCol input, .searchbarIndivCol select,.btn--search,.btn--filter,#filterText{
      font-size: 12px;
    }
      
    .CardAdvertiseDiv, .CardAdvertiseDivAccount{
      width: 47%;
    }
    .CardAdvertiseDivAccount{
      /* height: 250px; */
      font-size: 12px;
    }
  
  }
  @media only screen and (min-width: 850px) and (max-width: 992px) {
    #clearBtnDiv{
      padding-top: 3%;
    }
    #dropdownFilterMin{
      width: 40%;
    }
    .CardAdvertiseDivCommercialRent, .CardAdvertiseDivCommercialSale, .CardAdvertiseDivResidentialRent ,.CardAdvertiseDivResidentialSale{
      width: 47%;
    }
    .Card {
      /* margin: 1%; */
      font-size: 12px;
      width: 47%;
      /* height: 360px; */
      /* height: 240px; */
    }
    
    .CardPurchase{
      font-size: 12px;
      width: 47%; 
    }
    .CardResidentialSales{
      font-size: 12px;
      width: 47%; 
    }
    .CardResidential{
      font-size: 12px;
      width: 47%;
    }
  
    #heartButton i{
      font-size: medium;
    }
    #Card_district,#Card_type,#Card_preferred  {
      font-size: 11px;
    }
  
    #Card_image {
      width: 100%;
    }
  
    .searchbarIndivColBtn {
      display: block;
      width: auto;
    }
  
    .btn--filter {
      float: right;
    }
  
    .btn--search {
      float: right;
    }
  
    .propertyType .active,.propertyType button,.searchbarIndivCol input, .searchbarIndivCol select,.btn--search,.btn--filter,#filterText{
      font-size: 12px;
    }
      
    .CardAdvertiseDiv, .CardAdvertiseDivAccount{
      width: 47%;
    }
    .CardAdvertiseDivAccount{
      /* height: 300px; */
      font-size: 12px;
    }
  
  }
  @media only screen and (min-width: 992px) and (max-width: 1024px) {
    
  
  
    #dropdownFilterMin{
      width: 40%;
    }
    .CardAdvertiseDivCommercialRent, .CardAdvertiseDivCommercialSale, .CardAdvertiseDivResidentialRent ,.CardAdvertiseDivResidentialSale{
      width: 47%;
    }
    .Card {
      /* margin: 1%; */
      font-size: 12px;
      width: 47%;
      /* height: 360px; */
      /* height: 240px; */
    }
    
    .CardPurchase{
      font-size: 12px;
      width: 47%; 
    }
    .CardResidentialSales{
      font-size: 12px;
      width: 47%; 
    }
    .CardResidential{
      font-size: 12px;
      width: 47%;
    }
  
    #heartButton i{
      font-size: medium;
    }
    #Card_district,#Card_type,#Card_preferred  {
      font-size: 11px;
    }
  
    #Card_image {
      width: 100%;
    }
  
  
  
  
  
  
    .searchbarIndivColBtn {
      display: block;
      width: auto;
    }
  
    .btn--filter {
      float: right;
    }
  
    .btn--search {
      float: right;
    }
  
    .propertyType .active,.propertyType button,.searchbarIndivCol input, .searchbarIndivCol select,.btn--search,.btn--filter,#filterText{
      font-size: 12px;
    }

    .CardAdvertiseDiv, .CardAdvertiseDivAccount{
      width: 47%;
    }
  
  }
  @media only screen and (min-width: 1024px) and (max-width: 1180px) {
  
  

    .CardAdvertiseDivCommercialRent, .CardAdvertiseDivCommercialSale, .CardAdvertiseDivResidentialRent ,.CardAdvertiseDivResidentialSale{
      width: 47%;
    }
    .Card {
      /* margin: 1%; */
      font-size: 12px;
      width: 47%;
      /* height: 400px; */
      /* height: 240px; */
    }
    
    .CardPurchase{
      font-size: 12px;
      width: 47%; 
      height: 380px;
    }
    .CardResidentialSales{
      font-size: 12px;
      width: 47%; 
      height: 380px;
    }
    .CardResidential{
      font-size: 12px;
      width: 47%;
    }

    .CardAdvertiseDivCommercialSale{
      height: 380px;
    }
 
    .CardAdvertiseDivResidentialSale{
      height: 380px;
      
    }
  
  
    #heartButton i{
      font-size: medium;
    }
    #Card_district,#Card_type,#Card_preferred  {
      font-size: 11px;
    }
  
    #Card_image {
      width: 100%;
    }
  
    .searchbarIndivColBtn {
      display: block;
      width: auto;
    }
  
    .btn--filter {
      float: right;
    }
  
    .btn--search {
      float: right;
    }
  
    .propertyType .active,.propertyType button,.searchbarIndivCol input, .searchbarIndivCol select,.btn--search,.btn--filter,#filterText{
      font-size: 12px;
    }
  
  }
  @media only screen and (min-width: 1180px) and (max-width: 1280px) {
  
  
    /* .Card {
      margin: 1%;
      font-size: 12px;
      width: 220px;
    } */
  
    #Card_district,#Card_type {
      font-size: 13px;
    }
  
    #Card_image {
      width: 100%;
    }

    #Card_type{
      font-size: 12px;
    }
  
  
  }
  
  @media only screen and (min-width: 1280px) and (max-width: 1366px) {
  
  
    /* .Card {
      margin: 1%;
      font-size: 14px;
      width: 250px;
    }
   */
  
   #residentialRentInfo {
    height: 28%;
   }
    #Card_image {
      width: 100%;
    }

    #Card_type{
      font-size: 13px;
    }
  
  
  }
  
  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    /* .Card {
      margin: 1%;
      font-size: 15px;
      width: 260px;
    } */
  
    #Card_image {
      width: 100%;
    }

    #Card_type{
      font-size: 13px;
    }
  
  
  }
  
  @media only screen and (min-width: 1440px) and (max-width: 1600px) {
    /* #commercialSaleInfo{
      height: 33%;
    }
    #residentialPurchaseInfo{
      height: 28%;
    }
    #residentialRentInfo{
      height: 30%;
    } */

    #Card_type{
      font-size: 14px;
    }
  }
  
  @media only screen and (min-width: 1600px) and (max-width: 1680px) {
    /* .Card {
      margin: 2%;
    } */
  
  }
  
  @media only screen and (min-width: 1680px) and (max-width: 1920px) {
    /* .Card {
      margin: 2%;
    } */
  
  }
  
