/* @font-face {
  font-family: "sans-serif";
  src: url("./fonts/Karla-Light.ttf") format("ttf"),
    url("./fonts/Karla-Light.ttf") format("ttf");
  font-display: swap;
} */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'Karla', sans-serif; */
}


.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.btn--general{
  background-color: rgb(26, 23, 23);
  color:#fff;
  border-radius: 50px;
  margin: 15px 0 15px 0;
  margin-left: 1%;
  padding: 8px 20px 8px 20px;
  font-size: 18px;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
}

.btn--general:hover{
  transition: all 0.3s ease-out;
  background: rgba(26, 23, 23, 0.47);
  transition: 250ms;
}

.btn-blue{
  background-color: white;
  color:rgb(98, 125, 177);
  border-radius: 50px;
  margin: 15px 0 15px 0;
  padding: 8px 20px 8px 20px;
  font-size: 18px;
  outline: none;
  border: 2px solid rgb(98, 125, 177);
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
}

.btn-blue:hover{
  transition: all 0.3s ease-out;
  background: rgba(98, 125, 177, 0.47);
  transition: 250ms;
}

.btn-general-selected{
  background-color: rgb(26, 23, 23);
  color:#fff;
  border-radius: 50px;
  margin: 15px 0 15px 0;
  padding: 8px 20px 8px 20px;
  font-size: 18px;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
}

.btn-general-notSelected{
  background-color: white;
  color:black;
  border-radius: 50px;
  margin: 15px 0 15px 0;
  padding: 8px 20px 8px 20px;
  font-size: 18px;
  outline: none;
  border: 2px solid #000;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
}




.Adv{
  min-height: 140px;
  background-color: grey;
  color:white;
  text-align: center;
}

.btn--facebook{
  background-color:#4267B2;
  font-size: large;
  border: none;
  color: white;
  cursor: pointer;
}



@media screen and (max-width: 960px) {
 *{
  font-size: 14px;
 }
}