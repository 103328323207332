.notification{
    background-color: #33434D;
    margin: 5%;
    padding: 8px 20px;
    border-radius: 10px;
}

.notification-content{
    float: left;
    width: 95%;
}

.notification-title{
    font-weight: bold;
    color: white;
}

.notification-message{
    color: white;
}

.notification-close{
    float: right;
    display: flex;
    justify-content: center;
    font-size: 30px;
    width: 5%;
    cursor: pointer;

}
.notifications-container_position-topRight{
    z-index: 1;
    position: fixed;
    top: 60px;
    /* adjust the value as needed to position notifications below your navbar */
    right: 0;
    width: 400px;
    overflow: hidden;
    overflow: auto;
}
@media screen and (max-width: 960px) {
    .notifications-container_position-topRight{
        font-size: 11px;
        width: 100%;
    }
    .notification-close{
        font-size: 14px;
    }
}