.carousel {
    overflow: hidden;
  }
  
  .inner {
    white-space: nowrap;
    transition: transform 0.3s;
  }
  
  .carousel-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
  }
  
  .indicators {
    display: flex;
    justify-content: center;
  }
  
  .indicators > #buttonNextPre {
    /* margin: 5px; */
    background-color: #33434D;
    color:#fff;
    border-radius: 10px;
    margin: 15px 0 15px 0;
    padding: 10px 8px;
    width: 5%;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
    margin-left: 1%;
    margin-right: 1%;
  }
  
  .indicators > button.active {
    background-color: #33434D;
    color:#fff;
    border-radius: 50px;
    margin: 15px 0 15px 0;
    padding: 8px 20px 8px 20px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
  
  }
  
  .indicators > button.notActive {
    background-color: white;
  color:rgb(98, 125, 177);
  border-radius: 50px;
  margin: 15px 0 15px 0;
  padding: 8px 20px 8px 20px;
  outline: none;
  border: 2px solid rgb(98, 125, 177);
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  }
  @media only screen and (max-width: 412px) {
    .indicators > button.notActive,  .indicators > button.active {
      padding: 8px 10px;
    }
  }