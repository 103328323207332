.footer-container {
  background-color: #FAFAFA;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: black;
}

.footer-subscription>p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid black;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
  text-align: center;
  width: 200px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items>h2 {
  color: black;
}

.footer-link-items a {
  color: black;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #33434D;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #2d1e1e;
}

/* Social Icons */
.social-icon-link {
  color: black;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin-left: 15%;
}

.social-logo {
  color: black;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: black;
  margin-bottom: 16px;
}

#contractValue {
  display: inline-block;
  margin: 0 auto;
  width: 100%;

}

#totalContractHeader {
  font-size: 40px;
  color: black;
}

#footerDigit {
  background-color: #E8E9ED;
  display: inline-block;
  margin: 0 auto;
  border-radius: 10px;
  padding: 1%;
  margin-left: 0.5%;
  margin-top: 0.5%;
  color: black;
  font-size: 40px;
  /* animation: animate 2s linear infinite; */
  /* animation-duration: 2s;
  animation-name: animate; */
  animation-duration: 2s;
  -webkit-animation-name: boingInUp;
  animation-name: boingInUp;
}

@keyframes animate {
  from {transform:rotateX(0deg);}
  to {transform:rotateX(360deg);}
  /* 0% {
      transform:
                 perspective(200px) 
                 rotateX(0deg) 
                 rotateY(0deg);
  }

  50% {
      transform:
                 perspective(200px) 
                 rotateX(-180deg) 
                 rotateY(0deg);
  }

  100% {
      transform: 
                 perspective(200px) 
                 rotateX(180deg) 
                 rotateY(0deg);
  } */
}


@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
  #totalContractHeader{
    font-size: 20px;
  }
  #footerDigit {
    background-color: #FAFAFA;
    display: inline-block;
    margin: 0 auto;
    padding: 1%;
    margin-left: 0.5%;
    margin-top: 0.5%;
    color: black;
    font-size: 20px;
  }
  .footer-link-items{
    align-items: center;
  }
  #LoginButton,#signUpDiv,.footer-link-items{
    font-size: 12px;
  }

}

@media only screen and (min-width: 2560px) and (max-width: 3840px){
  #LoginButton{
    margin-left: 20%;
  }
  .footer-link-items{
    width: 200px;
  }
  #LoginButton,#signUpDiv,.footer-link-items{
    font-size: 20px;
  }
  
}

@-webkit-keyframes boingInUp {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: perspective(800px) rotateX(-90deg);
    transform: perspective(800px) rotateX(-90deg);
  }
  50% {
    opacity: 1;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: perspective(800px) rotateX(50deg);
    transform: perspective(800px) rotateX(50deg);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: perspective(800px) rotateX(0deg);
    transform: perspective(800px) rotateX(0deg);
  }
}

@keyframes boingInUp {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: perspective(800px) rotateX(-90deg);
    transform: perspective(800px) rotateX(-90deg);
  }
  50% {
    opacity: 1;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: perspective(800px) rotateX(50deg);
    transform: perspective(800px) rotateX(50deg);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: perspective(800px) rotateX(0deg);
    transform: perspective(800px) rotateX(0deg);
  }
}
