
  
.headerRow {
    /* margin-left: 10%;
    margin-top: 3%; */

    width: 100%;
    padding-left: 2%;
    font-size: 28px;
    font-weight: 700;
}

.toggleDivHome {
    display: flex;
    z-index: 1;
    position: relative;
    text-align: center;
    border-radius: 20px;
    margin-bottom: 1%;
    overflow: hidden;
}

#homePageWord {
    font-family: 'Oswald', sans-serif;
}

#commercialBackgroundHome {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.25;

}
#residentialBackgroundHome{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.25;
}

#landingDivHome {
    display: flex;
    flex-direction: row;
    height: 100%;
}

#landingDivHomeCommercial,#landingDivHomeResidential {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#landingDivHomeInner {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#landingDivHomeInnerFirst {
    width: 100%;
    border-right: 8px solid black;
    /* height: 100vh; */
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
}

#slideHeight{
    min-height: 30rem;
}


#landingDivHomeInnerFirstInner {
    width: 100%;
    height: 65vh;
    text-align: center;
    background-color: #f4f5f7b3;
    z-index: 1;
    padding: 8px;
    cursor: pointer;
    transform-style: preserve-3d;
    display: flex;

}

#frontDiv {
    width: 100%;
    /* height: 100%; */
    flex: 1;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#IwantTo {
    font-size: 30px;
}

#BUYRENTHOME {
    font-size: 60px;
    font-family: 'Oswald', sans-serif;
}

#backDiv {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.toggleBtn {
    padding: 10px 40px;
    border: none;
    color: black;
    font-size: 16px;
    background-color: rgba(244, 245, 247, 0.75);
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
}

.toggleBtn.active {
    background-color: #33434D;
    /* Green color */
    font-weight: bold;
    color: white
}

#homeTitle {
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 35px;
    font-weight: bold;
}

#homeSubTitle {
    font-size: 15px;
    padding-bottom: 1.5%;
    text-align: center;
}

.BuyInputHome {
    width: 60%;
    min-height: 30px;
    border-radius: 5px;
    border: 1px solid grey;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.searchBarHome,
.searchBarMobileHome {


    width: 80%;
    border-radius: 10px;
    padding: 10px 10px;
    background-color: #33434dbf;
    border: none;
}

#searchBaHome {
    align-items: center;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
}

.searchBarMobileHome {
    display: none;
}

.label-text {
    display: inline-block;
    padding-left: 10px;
    width: calc(100% - 24px);
    /* Adjust the width as needed */
}

#moreFilter {
    position: absolute;
    top: 100%;
    background-color: #FAFAFA;
    z-index: 1;
    margin-top: 1%;
    min-width: 40%;
    overflow-y: auto;
    max-height: 300px;
    padding: 1%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
}

.searchbarIndivCol {
    width: 100%;
}

#optionBudget {
    /* color: grey;
    font-weight: bold; */
    padding-top: 2%;
    padding-bottom: 2%;
    cursor: pointer;
}

#optionBudget:hover {
    background-color: #CCC;
}

.searchbarIndivCol input,
.searchbarIndivCol select {
    border: none;
    padding: 8px 10px;
    outline: none;
    color: black;
    width: 100%;
}

.searchbarIndivColBtn {
    display: flex;
    justify-content: center;
    width: fit-content;
}

#popover-containedHome {
    margin-left: -20%;
    border-radius: 10px;
    width: 90%;
    background: rgba(255, 255, 255, 0.936);
}

#residentialDropDown {
    color: black;
    width: 100%;
    outline: none;
    padding: 8px 10px;
    border: none;
    background-color: transparent;
}

#landingPage {
    background-color: #FAFAFA;
    padding-top: 3%;
    width: 95%;
    margin-left: 2.5%;
    border-radius: 20px;
    min-height: 80vh;
    background: #FAFAFA;
    /* fallback for non-supported browsers */
    background: -webkit-linear-gradient(left, #8cd1c7, #316190);
    /* for Safari 5.1 to 6.0 */
    background: -o-linear-gradient(right, #8cd1c7, #316190);
    /* for Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, #8cd1c7, #316190);
    /* for Firefox 3.6 to 15 */
    background: linear-gradient(to right, #8cd1c7, #316190);
    /* standard syntax */
}

#landingPageLeft {
    float: left;
    padding-left: 10%;
    width: 50%;
}

#landingPageFirstItem {
    background-color: #96D2C8;
    border-radius: 20px;
    padding: 10px 10px 10px 10px;
    width: fit-content;
    font-size: 15px;
    margin-bottom: 5%;
}

#landingPageSecondItem {
    padding-top: 20%;
    font-size: 50px;
    font-weight: bold;
}

#landingPageThirdItem {
    font-size: 15px;
    padding-bottom: 3%;
    text-align: justify;
    line-height: 1.8;
}

#landingPageFourthtItem {
    width: 700px;
    background-color: #FFFFFF;
    border-radius: 20px;
    min-height: 80px;
    border: 3px solid #e6e6e6;
}



#landingPageRight {

    float: right;
    width: 50%;
}

#homeRowone {
    background-color: #FFFFFF;
    padding-top: 3%;
    /* position: relative; */
    /* overflow: hidden; */
}

#titleHomeRowOne {
    float: left;
    display: flex;
    flex-direction: row;
    width: 60%;
    margin-left: 10%;
    /* this centers the text within the container */
}

#star {
    width: 5%;
    height: 5%;
}

.homeProductList {
    /* margin-left: 10%; */
    padding-top: 2%;
    width: 100%;
}

#homeRowtwo {
    background-color: #FAFAFA;
    padding-top: 3%;
    padding-bottom: 1px;
    position: relative;
    overflow: hidden;
}

#rowThree {
    float: right;
    background-color: #FFFFFF;
    width: 50%;
}

#rowFourLeft {
    padding-left: 10%;
    float: left;
    width: 50%;
}

#rowFourRight {
    float: left;
    width: 50%;
    margin-top: 7%;
    padding-left: 10%;
    overflow-x: hidden;
    /* display: none; */
}
.animate-slideRightReturn {
    -webkit-animation-name: slideRightReturn;
    animation-name: slideRightReturn;
    animation-duration: 2s;
}
.animate-slideLeftReturn {
    -webkit-animation-name: slideLeftReturn;
    animation-name: slideLeftReturn;
    animation-duration: 2s;
}
.animate-perspectiveUpReturn {
    -webkit-animation-name: perspectiveUpReturn;
    animation-name: perspectiveUpReturn;
    animation-duration: 2s;
}

.gifIcon {
    width: 13%;
}

.gifDiv {
    display: flex;
    align-items: center;
    margin-bottom: 5%;
}
.carousel-slide {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .carousel-slide.show {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }


#homeRowTwoCircle1 {
    position: absolute;
    top: -200px;
    left: -200px;
    width: 500px;
    height: 500px;
    /* -webkit-border-radius: 25px;
    -moz-border-radius: 25px; */
    border-radius: 50%;
    background: #8ad7cba0;
    z-index: -1;
}

#homeRowTwoCircle2 {
    position: absolute;
    top: 300px;
    right: -200px;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: #30628E;
    z-index: -1;
}

.linkLabel {

    text-decoration: none;
    color: white;
}

.blackBtn {
    background-color: #33434D;
    margin-right: 10%;
    padding: 15px 50px 15px 50px;
    border-radius: 10px;
    border: none;
}

#landingPageHomeImg {
    width: 60%;
    height: 530px;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    margin-left: 20%;
}

#landingPageHomeSubTitle {
    margin-top: 10%;
    font-size: 30px;
    font-weight: bold;
}

#landingPageHomeSubDescription_2 {
    width: 60%;
    padding-top: 3%;
    line-height: 2;
}

#rowThreepic {
    width: 30%;
    margin-left: 10%;
    margin-right: 10%;
}

#rowFourText {
    padding-top: 16px;
    line-height: 2;
    text-align: justify;
    width: 90%;
}

#rowFourSubTitle {
    margin: 0;
    font-size: 20px;
}

@media only screen and (max-width: 290px) {
    #slideHeight{
        min-height: 20rem;
    }
    #moreFilter{
        width: 100%;
    }
    #homeRowone,#homeRowtwo{
        padding-top: 15%;
    }
    #landingDivHomeInnerFirstInner{
        height: inherit;
    }
    #frontDiv{
        display: flex;
        flex-direction: column;
        justify-self: center
    }
    #commercialBackgroundHome{
        width: 100%;
        height: 50%;
    }
    #residentialBackgroundHome{
        width: 100%;
        height: 50%;
        top:initial
    }
    #landingDivHomeInner{
        width: 100%;
        height: 100%;
    }
    #landingDivHome{
        flex-direction: column;
    }
    #landingDivHomeCommercial, #landingDivHomeResidential{
        flex-direction: column;
        width: 100%;
        height: 50%;
    }
    #landingDivHomeInnerFirst{
        height: 100%;
        border-right: none;
        border-bottom: 8px solid black;
    }
    #IwantTo{
        font-size: 14px;
    }
    #BUYRENTHOME{
        font-size: 30px;
    }
    
    .gifDiv {
        /* display: block; */
    }

    #landingPage {
        min-height: 53vh;
    }

    #landingPageRight {
        float: none;
        width: 100%;
    }

    #landingPageHomeImg {
        margin-top: 0%;
        width: 55%;
        height: 300px;
        margin-left: 24%;
    }

    #landingPageLeft {
        float: none;
        width: 100%;
        padding-left: 0%;
    }

    #landingPageSecondItem {
        text-align: center;
        padding-top: 0%;
        font-size: 18px;
        margin-bottom: 2%;
    }


    #titleHomeRowOne {
        width: 40%;
    }

    #landingPageHomeSubTitle {
        font-size: 13px;
    }

    .homeProductList {
        padding-top: 5%
    }

    .blackBtn {
        padding: 10px 15px 10px 15px;
    }

    #landingPageHomeSubDescription_2 {
        width: 100%;
    }

    #rowThreeBtn {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }

    #rowFourLeft {
        padding-left: 0;
        padding: 15px;
        text-align: center;
        float: none;
        width: 100%;
    }

    #rowFourRight {
        float: none;
        width: 100%;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }

    .searchBarHome {
        width: 95%;
    }

    .searchBarMobileHome {
        position: relative;
        left: 2%;
        width: 96%;
        display: block;
        padding: 8px 10px;

    }

    #rowThreepic {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 15px;
    }

    #rowThree {
        float: none;
        width: 100%;
        padding: 15px;
        text-align: center;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    #rowFourText {
        width: 100%;
    }

    .gifIcon {
        width: 10%;
    }

    .linkLabel,
    #landingPageHomeSubDescription_2,
    .blackBtn,
    #rowFourText,
    #rowFourRight,
    #homeSubTitle,
    .toggleBtn {
        font-size: 10px;
    }

    #rowFourSubTitle,
    #landingPageHomeSubTitle,
    .headerRow,
    #homeTitle {
        font-size: 14px;
    }

}

@media only screen and (min-width: 290px) and (max-width: 325px) {
    #slideHeight{
        min-height: 20rem;
    }
    #moreFilter{
        width: 100%;
    }
    #homeRowone,#homeRowtwo{
        padding-top: 15%;
    }
    #landingDivHomeInnerFirstInner{
        height: inherit;
    }
    #frontDiv{
        display: flex;
        flex-direction: column;
        justify-content: center
    }
    #commercialBackgroundHome{
        width: 100%;
        height: 50%;
    }
    #residentialBackgroundHome{
        width: 100%;
        height: 50%;
        top:initial
    }
    #landingDivHomeInner{
        width: 100%;
        height: 100%;
    }
    #landingDivHome{
        flex-direction: column;
    }
    #landingDivHomeCommercial,#landingDivHomeResidential{
        flex-direction: column;
        width: 100%;
        height: 50%;
    }
    #landingDivHomeInnerFirst{
        height: 100%;
        border-right: none;
        border-bottom: 8px solid black;
    }
    #IwantTo{
        font-size: 14px;
    }
    #BUYRENTHOME{
        font-size: 30px;
    }
    .gifDiv {
        /* display: block; */
    }

    #landingPage {
        min-height: 53vh;
    }

    #landingPageRight {
        float: none;
        width: 100%;
    }

    #landingPageHomeImg {
        margin-top: 0%;
        width: 55%;
        height: 300px;
        margin-left: 24%;
    }

    #landingPageLeft {
        float: none;
        width: 100%;
        padding-left: 0%;
    }

    #landingPageSecondItem {
        text-align: center;
        padding-top: 0%;
        font-size: 20px;
        margin-bottom: 2%;
    }


    #titleHomeRowOne {
        width: 40%;
    }

    #landingPageHomeSubTitle {
        font-size: 15px;
    }

    .homeProductList {
        padding-top: 5%;
    }

    .blackBtn {
        padding: 10px 15px 10px 15px;
    }

    #landingPageHomeSubDescription_2 {
        width: 100%;
    }

    #rowThreeBtn {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }

    #rowFourLeft {
        padding-left: 0;
        padding: 15px;
        text-align: center;
        float: none;
        width: 100%;
    }

    #rowFourRight {
        float: none;
        width: 100%;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }

    .searchBarHome {
        width: 95%;
    }

    .searchBarMobileHome {
        position: relative;
        left: 2%;
        width: 96%;
        display: block;
        padding: 8px 10px;

    }

    #rowThreepic {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 15px;
    }

    #rowThree {
        float: none;
        width: 100%;
        padding: 15px;
        text-align: center;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    #rowFourText {
        width: 100%;
    }

    .gifIcon {
        width: 10%;
    }

    .linkLabel,
    #landingPageHomeSubDescription_2,
    .blackBtn,
    #rowFourText,
    #rowFourRight,
    #homeSubTitle,
    .toggleBtn {
        font-size: 12px;
    }

    #rowFourSubTitle,
    #landingPageHomeSubTitle,
    .headerRow,
    #homeTitle {
        font-size: 16px;
    }


}

@media only screen and (min-width: 325px) and (max-width: 412px) {
    #slideHeight{
        min-height: 20rem;
    }
    #moreFilter{
        width: 100%;
    }
    #homeRowone,#homeRowtwo{
        padding-top: 15%;
    }
    #landingDivHomeInnerFirstInner{
        height: inherit;
    }
    #frontDiv{
        display: flex;
        flex-direction: column;
        justify-content: center
    }
    #commercialBackgroundHome{
        width: 100%;
        height: 50%;
    }
    #residentialBackgroundHome{
        width: 100%;
        height: 50%;
        top:initial
    }
    #landingDivHomeInner{
        width: 100%;
        height: 100%;
    }
    #landingDivHome{
        flex-direction: column;
    }
    #landingDivHomeCommercial,#landingDivHomeResidential{
        flex-direction: column;
        width: 100%;
        height: 50%;
    }
    #landingDivHomeInnerFirst{
        height: 100%;
        border-right: none;
        border-bottom: 8px solid black;
    }
    #IwantTo{
        font-size: 14px;
    }
    #BUYRENTHOME{
        font-size: 30px;
    }



    .gifDiv {
        /* display: block; */
    }

    #landingPage {
        min-height: 53vh;
    }

    #landingPageRight {
        float: none;
        width: 100%;
    }

    #landingPageHomeImg {
        margin-top: 0%;
        width: 55%;
        height: 300px;
        margin-left: 24%;
    }

    #landingPageLeft {
        float: none;
        width: 100%;
        padding-left: 0%;
    }

    #landingPageSecondItem {
        text-align: center;
        padding-top: 0%;
        font-size: 20px;
        margin-bottom: 2%;
    }


    #titleHomeRowOne {
        width: 40%;
    }

    #landingPageHomeSubTitle {
        font-size: 15px;
    }

    .homeProductList {
        padding-top: 5%;
    }

    .blackBtn {
        padding: 10px 15px 10px 15px;
    }

    #landingPageHomeSubDescription_2 {
        width: 100%;
    }

    #rowThreeBtn {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }

    #rowFourLeft {
        padding-left: 0;
        padding: 15px;
        text-align: center;
        float: none;
        width: 100%;
    }

    #rowFourRight {
        float: none;
        width: 100%;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }

    .searchBarHome {
        width: 95%;
    }



    #rowThreepic {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 15px;
    }

    #rowThree {
        float: none;
        width: 100%;
        padding: 15px;
        text-align: center;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    #rowFourText {
        width: 100%;
    }

    .gifIcon {
        width: 10%;
    }

    .linkLabel,
    #landingPageHomeSubDescription_2,
    .blackBtn,
    #rowFourText,
    #rowFourRight,
    #homeSubTitle,
    .toggleBtn {
        font-size: 12px;
    }

    #rowFourSubTitle,
    #landingPageHomeSubTitle,
    .headerRow,
    #homeTitle {
        font-size: 16px;
    }



}

@media only screen and (min-width: 412px) and (max-width: 540px) {
    #slideHeight{
        min-height: 20rem;
    }
    #moreFilter{
        width: 100%;
    }
    #homeRowone,#homeRowtwo{
        padding-top: 15%;
    }
    #landingDivHomeInnerFirstInner{
        height: inherit;
    }
    #frontDiv{
        display: flex;
        flex-direction: column;
        justify-content: center
    }
    #commercialBackgroundHome{
        width: 100%;
        height: 50%;
    }
    #residentialBackgroundHome{
        width: 100%;
        height: 50%;
        top:initial
    }
    #landingDivHomeInner{
        width: 100%;
        height: 100%;
    }
    #landingDivHome{
        flex-direction: column;
    }
    #landingDivHomeCommercial,#landingDivHomeResidential{
        flex-direction: column;
        width: 100%;
        height: 50%;
    }
    #landingDivHomeInnerFirst{
        height: 100%;
        border-right: none;
        border-bottom: 8px solid black;
    }
    #IwantTo{
        font-size: 14px;
    }
    #BUYRENTHOME{
        font-size: 30px;
    }

    .gifDiv {
        /* display: block; */
    }

    #landingPage {
        min-height: 53vh;
    }

    #landingPageRight {
        float: none;
        width: 100%;
    }

    #landingPageHomeImg {
        margin-top: 0%;
        width: 55%;
        height: 300px;
        margin-left: 24%;
    }

    #landingPageLeft {
        float: none;
        width: 100%;
        padding-left: 0%;
    }

    #landingPageSecondItem {
        text-align: center;
        padding-top: 0%;
        font-size: 20px;
        margin-bottom: 2%;
    }


    #titleHomeRowOne {
        width: 40%;
    }

    #landingPageHomeSubTitle {
        font-size: 15px;
    }

    .homeProductList {
        padding-top: 5%;
    }

    .blackBtn {
        padding: 10px 15px 10px 15px;
    }

    #landingPageHomeSubDescription_2 {
        width: 100%;
    }

    #rowThreeBtn {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }

    #rowFourLeft {
        padding-left: 0;
        padding: 15px;
        text-align: center;
        float: none;
        width: 100%;
    }

    #rowFourRight {
        float: none;
        width: 100%;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }

    .searchBarHome {
        width: 95%;
    }



    #rowThreepic {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 15px;
    }

    #rowThree {
        float: none;
        width: 100%;
        padding: 15px;
        text-align: center;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    #rowFourText {
        width: 100%;
    }

    .gifIcon {
        width: 10%;
    }

    .linkLabel,
    #landingPageHomeSubDescription_2,
    .blackBtn,
    #rowFourText,
    #rowFourRight,
    #homeSubTitle,
    .toggleBtn {
        font-size: 12px;
    }

    #rowFourSubTitle,
    #landingPageHomeSubTitle,
    .headerRow,
    #homeTitle {
        font-size: 16px;
    }

}

@media only screen and (min-width: 540px) and (max-width: 640px) {
    #slideHeight{
        min-height: 20rem;
    }
    #moreFilter{
        width: 100%;
    }
    #homeRowone,#homeRowtwo{
        padding-top: 15%;
    }
    #landingDivHomeInnerFirstInner{
        height: inherit;
    }
    #frontDiv{
        display: flex;
        flex-direction: column;
        justify-content: center
    }
    #commercialBackgroundHome{
        width: 100%;
        height: 50%;
    }
    #residentialBackgroundHome{
        width: 100%;
        height: 50%;
        top:initial
    }
    #landingDivHomeInner{
        width: 100%;
        height: 100%;
    }
    #landingDivHome{
        flex-direction: column;
    }
    #landingDivHomeCommercial,#landingDivHomeResidential{
        flex-direction: column;
        width: 100%;
        height: 50%;
    }
    #landingDivHomeInnerFirst{
        height: 100%;
        border-right: none;
        border-bottom: 8px solid black;
    }
    #IwantTo{
        font-size: 14px;
    }
    #BUYRENTHOME{
        font-size: 30px;
    }

    .gifDiv {
        /* display: block; */
    }

    #landingPage {
        min-height: 53vh;
    }

    #landingPageRight {
        float: none;
        width: 100%;
    }

    #landingPageHomeImg {
        margin-top: 0%;
        width: 55%;
        height: 300px;
        margin-left: 24%;
    }

    #landingPageLeft {
        float: none;
        width: 100%;
        padding-left: 0%;
    }

    #landingPageSecondItem {
        text-align: center;
        padding-top: 0%;
        font-size: 20px;
        margin-bottom: 2%;
    }


    #titleHomeRowOne {
        width: 40%;
    }

    #landingPageHomeSubTitle {
        font-size: 15px;
    }

    .homeProductList {
        padding-top: 5%;
    }

    .blackBtn {
        padding: 10px 15px 10px 15px;
    }

    #landingPageHomeSubDescription_2 {
        width: 100%;
    }

    #rowThreeBtn {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }

    #rowFourLeft {
        padding-left: 0;
        padding: 15px;
        text-align: center;
        float: none;
        width: 100%;
    }

    #rowFourRight {
        float: none;
        width: 100%;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }

    .searchBarHome {
        width: 95%;
    }



    #rowThreepic {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 15px;
    }

    #rowThree {
        float: none;
        width: 100%;
        padding: 15px;
        text-align: center;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    #rowFourText {
        width: 100%;
    }

    .gifIcon {
        width: 10%;
    }

    .linkLabel,
    #landingPageHomeSubDescription_2,
    .blackBtn,
    #rowFourText,
    #rowFourRight,
    #homeSubTitle,
    .toggleBtn {
        font-size: 12px;
    }

    #rowFourSubTitle,
    #landingPageHomeSubTitle,
    .headerRow,
    #homeTitle {
        font-size: 16px;
    }
}

@media only screen and (min-width: 640px) and (max-width: 712px) {
    #slideHeight{
        min-height: 20rem;
    }
    #moreFilter{
        width: 100%;
    }
    #homeRowone,#homeRowtwo{
        padding-top: 15%;
    }
    #landingDivHomeInnerFirstInner{
        height: inherit;
    }
    #frontDiv{
        display: flex;
        flex-direction: column;
        justify-content: center
    }
    #commercialBackgroundHome{
        width: 100%;
        height: 50%;
    }
    #residentialBackgroundHome{
        width: 100%;
        height: 50%;
        top:initial
    }
    #landingDivHomeInner{
        width: 100%;
        height: 100%;
    }
    #landingDivHome{
        flex-direction: column;
    }
    #landingDivHomeCommercial,#landingDivHomeResidential{
        flex-direction: column;
        width: 100%;
        height: 50%;
    }
    #landingDivHomeInnerFirst{
        height: 100%;
        border-right: none;
        border-bottom: 8px solid black;
    }
    #IwantTo{
        font-size: 14px;
    }
    #BUYRENTHOME{
        font-size: 30px;
    }

    .gifDiv {
        /* display: block; */
    }

    #landingPage {
        min-height: 53vh;
    }

    #landingPageRight {
        float: none;
        width: 100%;
    }

    #landingPageHomeImg {
        margin-top: 0%;
        width: 45%;
        margin-left: 27%;
    }

    #landingPageLeft {
        float: none;
        width: 100%;
        padding-left: 0%;
    }

    #landingPageSecondItem {
        text-align: center;
        padding-top: 0%;
        font-size: 20px;
        margin-bottom: 2%;
    }


    #titleHomeRowOne {
        width: 40%;
    }

    #landingPageHomeSubTitle {
        font-size: 15px;
    }

    .homeProductList {
        padding-top: 5%;
    }

    .blackBtn {
        padding: 10px 15px 10px 15px;
    }

    #rowThreeBtn {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }

    #landingPageHomeSubDescription_2 {
        width: 100%;
    }

    #rowFourLeft {
        padding-left: 0;
        padding: 15px;
        text-align: center;
        float: none;
        width: 100%;
    }

    #rowFourRight {
        float: none;
        width: 100%;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }



    .searchBarMobileHome {
        position: relative;
        left: 2%;
        width: 96%;
        display: block;
        padding: 8px 10px;

    }

    #rowThreepic {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 15px;
        display: flex;
        flex-direction: column;
    }

    #rowThree {
        float: none;
        width: 100%;
        padding: 15px;
        position: relative;
        text-align: center;
    }

    #rowFourText {
        width: 100%;
    }

    .gifIcon {
        width: 10%;
    }

    .linkLabel,
    #landingPageHomeSubDescription_2,
    .blackBtn,
    #rowFourText,
    #rowFourRight {
        font-size: 14px;
    }

    #rowFourSubTitle,
    #landingPageHomeSubTitle,
    .headerRow {
        font-size: 16px;
    }
}

@media only screen and (min-width: 712px) and (max-width: 768px) {
    #moreFilter{
        width: 100%;
    }
    #homeRowone,#homeRowtwo{
        padding-top: 15%;
    }
    #landingDivHomeInnerFirstInner{
        height: inherit;
    }
    #frontDiv{
        display: flex;
        flex-direction: column;
        justify-content: center
    }
    #commercialBackgroundHome{
        width: 100%;
        height: 50%;
    }
    #residentialBackgroundHome{
        width: 100%;
        height: 50%;
        top:initial
    }
    #landingDivHomeInner{
        width: 100%;
        height: 100%;
    }
    #landingDivHome{
        flex-direction: column;
    }
    #landingDivHomeCommercial,#landingDivHomeResidential{
        flex-direction: column;
        width: 100%;
        height: 50%;
    }
    #landingDivHomeInnerFirst{
        height: 100%;
        border-right: none;
        border-bottom: 8px solid black;
    }
    #IwantTo{
        font-size: 14px;
    }
    #BUYRENTHOME{
        font-size: 30px;
    }

    .gifDiv {
        /* display: block; */
    }

    #landingPage {
        min-height: 53vh;
    }

    #landingPageRight {
        float: none;
        width: 100%;
    }

    #landingPageHomeImg {
        margin-top: 0%;
        width: 55%;
        height: 300px;
        margin-left: 24%;
    }

    #landingPageLeft {
        float: none;
        width: 100%;
        padding-left: 0%;
    }

    #landingPageSecondItem {
        text-align: center;
        padding-top: 0%;
        font-size: 20px;
        margin-bottom: 2%;
    }


    #titleHomeRowOne {
        width: 40%;
    }

    #landingPageHomeSubTitle {
        font-size: 15px;
    }

    .homeProductList {
        padding-top: 5%;
    }

    .blackBtn {
        padding: 10px 15px 10px 15px;
    }

    #landingPageHomeSubDescription_2 {
        width: 100%;
    }

    #rowThreeBtn {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }

    #rowFourLeft {
        padding-left: 0;
        padding: 15px;
        text-align: center;
        float: none;
        width: 100%;
    }

    #rowFourRight {
        float: none;
        width: 100%;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }



    .searchBarHome {

        width: 96%;


    }

    #rowThreepic {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 15px;
    }

    #rowThree {
        float: none;
        width: 100%;
        padding: 15px;
        text-align: center;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    #rowFourText {
        width: 100%;
    }

    .gifIcon {
        width: 10%;
    }

    .linkLabel,
    #landingPageHomeSubDescription_2,
    .blackBtn,
    #rowFourText,
    #rowFourRight {
        font-size: 12px;
    }

    #rowFourSubTitle,
    #landingPageHomeSubTitle,
    .headerRow {
        font-size: 16px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 800px) {
    #homeRowone,#homeRowtwo{
        padding-top: 15%;
    }
    #landingDivHomeInnerFirstInner{
        height: inherit;
    }
    #frontDiv{
        display: flex;
        flex-direction: column;
        justify-content: center
    }
    #commercialBackgroundHome{
        width: 100%;
        height: 50%;
    }
    #residentialBackgroundHome{
        width: 100%;
        height: 50%;
        top:initial
    }
    #landingDivHomeInner{
        width: 100%;
        height: 100%;
    }
    #landingDivHome{
        flex-direction: column;
    }
    #landingDivHomeCommercial,#landingDivHomeResidential{
        flex-direction: column;
        width: 100%;
        height: 50%;
    }
    #landingDivHomeInnerFirst{
        height: 100%;
        border-right: none;
        border-bottom: 8px solid black;
    }
    #IwantTo{
        font-size: 14px;
    }
    #BUYRENTHOME{
        font-size: 30px;
    }

    .gifDiv {
        /* display: block; */
    }

    #landingPage {
        min-height: 53vh;
    }

    #landingPageRight {
        float: none;
        width: 100%;
    }

    #landingPageHomeImg {
        margin-top: 0%;
        width: 45%;
        margin-left: 27%;
    }

    #landingPageLeft {
        float: none;
        width: 100%;
        padding-left: 0%;
    }

    #landingPageSecondItem {
        text-align: center;
        padding-top: 0%;
        font-size: 30px;
        margin-bottom: 2%;
    }


    #titleHomeRowOne {
        width: 40%;
    }

    #landingPageHomeSubTitle {
        font-size: 15px;
    }

    .homeProductList {
        /* margin-left: 5%; */
    }

    .blackBtn {
        padding: 10px 15px 10px 15px;
    }

    #rowThreeBtn {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }

    #landingPageHomeSubDescription_2 {
        width: 100%;
    }

    #rowFourLeft {
        padding-left: 0;
        padding: 15px;
        text-align: center;
        float: none;
        width: 100%;
    }

    #rowFourRight {
        float: none;
        width: 100%;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }


    #rowThreepic {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        display: flex;
        flex-direction: column;
        padding: 15px;
    }

    #rowThree {
        float: none;
        width: 100%;
        padding: 15px;
        text-align: center;
        position: relative;
    }

    #rowFourText {
        width: 100%;
    }

    .gifIcon {
        width: 10%;
    }

    .linkLabel,
    #landingPageHomeSubDescription_2,
    .blackBtn,
    #rowFourText,
    #rowFourRight {
        font-size: 14px;
    }

    #rowFourSubTitle,
    #landingPageHomeSubTitle,
    .headerRow {
        font-size: 16px;
    }
}

@media only screen and (min-width: 800px) and (max-width: 850px) {
    #homeRowone,#homeRowtwo{
        padding-top: 15%;
    }
    #landingDivHomeInnerFirstInner{
        height: inherit;
    }
    #frontDiv{
        display: flex;
        flex-direction: column;
        justify-content: center
    }
    #commercialBackgroundHome{
        width: 100%;
        height: 50%;
    }
    #residentialBackgroundHome{
        width: 100%;
        height: 50%;
        top:initial
    }
    #landingDivHomeInner{
        width: 100%;
        height: 100%;
    }
    #landingDivHome{
        flex-direction: column;
    }
    #landingDivHomeCommercial,#landingDivHomeResidential{
        flex-direction: column;
        width: 100%;
        height: 50%;
    }
    #landingDivHomeInnerFirst{
        height: 100%;
        border-right: none;
        border-bottom: 8px solid black;
    }
    #IwantTo{
        font-size: 14px;
    }
    #BUYRENTHOME{
        font-size: 40px;
    }

    .gifDiv {
        /* display: block; */
    }

    #landingPage {
        min-height: 53vh;
    }

    #landingPageRight {
        float: none;
        width: 100%;
    }

    #landingPageHomeImg {
        margin-top: 0%;
        width: 45%;
        margin-left: 27%;
    }

    .searchBarHome,
    .searchBarMobileHome {
        width: 90%;
    }

    #landingPageLeft {
        float: none;
        width: 100%;
        padding-left: 0%;
    }

    #landingPageSecondItem {
        text-align: center;
        padding-top: 0%;
        font-size: 30px;
        margin-bottom: 2%;
    }


    #titleHomeRowOne {
        width: 40%;
    }

    #landingPageHomeSubTitle {
        font-size: 15px;
    }

    .homeProductList {
        /* margin-left: 5%; */
        /* width: 90%; */
    }

    .blackBtn {
        padding: 10px 15px 10px 15px;
    }

    #rowThreeBtn {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }

    #landingPageHomeSubDescription_2 {
        width: 100%;
    }

    #rowFourLeft {
        padding-left: 0;
        padding: 15px;
        text-align: center;
        float: none;
        width: 100%;
    }

    #rowFourRight {
        float: none;
        width: 100%;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }


    .searchBarMobileHome {
        position: relative;
        left: 2%;
        width: 96%;
        display: block;
        padding: 8px 10px;

    }

    #rowThreepic {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 15px;
    }

    #rowThree {
        float: none;
        width: 100%;
        padding: 15px;
        text-align: center;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    #rowFourText {
        width: 100%;
    }

    .gifIcon {
        width: 10%;
    }

    .linkLabel,
    #landingPageHomeSubDescription_2,
    .blackBtn,
    #rowFourText,
    #rowFourRight {
        font-size: 16px;
    }

    #rowFourSubTitle,
    #landingPageHomeSubTitle,
    .headerRow {
        font-size: 20px;
    }
}

@media only screen and (min-width: 850px) and (max-width: 992px) {
    #homeRowone,#homeRowtwo{
        padding-top: 15%;
    }
    #landingDivHomeInnerFirstInner{
        height: inherit;
    }
    #frontDiv{
        display: flex;
        flex-direction: column;
        justify-content: center
    }
    #commercialBackgroundHome{
        width: 100%;
        height: 50%;
    }
    #residentialBackgroundHome{
        width: 100%;
        height: 50%;
        top:initial
    }
    #landingDivHomeInner{
        width: 100%;
        height: 100%;
    }
    #landingDivHome{
        flex-direction: column;
    }
    #landingDivHomeCommercial,#landingDivHomeResidential{
        flex-direction: column;
        width: 100%;
        height: 50%;
    }
    #landingDivHomeInnerFirst{
        height: 100%;
        border-right: none;
        border-bottom: 8px solid black;
    }
    #IwantTo{
        font-size: 14px;
    }
    #BUYRENTHOME{
        font-size: 30px;
    }

    .gifDiv {
        /* display: block; */
    }

    #landingPage {
        min-height: 53vh;
    }

    #landingPageRight {
        float: none;
        width: 100%;
    }

    #landingPageHomeImg {
        margin-top: 0%;
        width: 45%;
        margin-left: 27%;
    }

    .searchBarHome,
    .searchBarMobileHome {
        width: 70%;
    }

    #landingPageLeft {
        float: none;
        width: 100%;
        padding-left: 0%;
    }

    #landingPageSecondItem {
        text-align: center;
        padding-top: 0%;
        font-size: 30px;
        margin-bottom: 2%;
    }


    #titleHomeRowOne {
        width: 40%;
    }

    #landingPageHomeSubTitle {
        font-size: 15px;
    }

    .homeProductList {
        /* margin-left: 5%; */
        /* width: 90%; */
    }

    .blackBtn {
        padding: 10px 15px 10px 15px;
    }

    #rowThreeBtn {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }

    #landingPageHomeSubDescription_2 {
        width: 100%;
    }

    #rowFourLeft {
        padding-left: 0;
        padding: 15px;
        text-align: center;
        float: none;
        width: 100%;
    }

    #rowFourRight {
        float: none;
        width: 100%;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }


    .searchBarMobileHome {
        position: relative;
        left: 2%;
        width: 96%;
        display: block;
        padding: 8px 10px;

    }

    #rowThreepic {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 15px;
    }

    #rowThree {
        float: none;
        width: 100%;
        padding: 15px;
        text-align: center;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    #rowFourText {
        width: 100%;
    }

    .gifIcon {
        width: 10%;
    }

    .linkLabel,
    #landingPageHomeSubDescription_2,
    .blackBtn,
    #rowFourText,
    #rowFourRight {
        font-size: 16px;
    }

    #rowFourSubTitle,
    #landingPageHomeSubTitle,
    .headerRow {
        font-size: 20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .gifDiv {
        /* display: block; */
    }

    #landingPage {
        min-height: 53vh;
    }

    #landingPageRight {
        float: none;
        width: 100%;
    }

    #landingPageHomeImg {
        margin-top: 0%;
        width: 45%;
        margin-left: 27%;
    }

    .searchBarHome,
    .searchBarMobileHome {
        width: 70%;
    }

    #landingPageLeft {
        float: none;
        width: 100%;
        padding-left: 0%;
    }

    #landingPageSecondItem {
        text-align: center;
        padding-top: 0%;
        font-size: 30px;
        margin-bottom: 2%;
    }


    #titleHomeRowOne {
        width: 40%;
    }

    #landingPageHomeSubTitle {
        font-size: 15px;
    }

    .homeProductList {
        /* margin-left: 5%; */
        /* width: 90%; */
    }

    .blackBtn {
        padding: 10px 15px 10px 15px;
    }

    #rowThreeBtn {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }

    #landingPageHomeSubDescription_2 {
        width: 100%;
    }

    #rowFourLeft {
        padding-left: 0;
        padding: 15px;
        text-align: center;
        float: none;
        width: 100%;
    }

    #rowFourRight {
        float: none;
        width: 100%;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }


    .searchBarMobileHome {
        position: relative;
        left: 2%;
        width: 96%;
        display: block;
        padding: 8px 10px;

    }

    #rowThreepic {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 15px;
    }

    #rowThree {
        float: none;
        width: 100%;
        padding: 15px;
        text-align: center;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    #rowFourText {
        width: 100%;
    }

    .gifIcon {
        width: 10%;
    }

    .linkLabel,
    #landingPageHomeSubDescription_2,
    .blackBtn,
    #rowFourText,
    #rowFourRight {
        font-size: 16px;
    }

    #rowFourSubTitle,
    #landingPageHomeSubTitle,
    .headerRow {
        font-size: 20px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1100px) {
    #BUYRENTHOME{
        font-size: 60px;
    }

    #IwantTo{
        font-size: 25px;
    }

    .gifDiv {
        /* display: block; */
    }

    #landingPage {
        min-height: 53vh;
    }

    #landingPageRight {
        float: none;
        width: 100%;
    }

    #landingPageHomeImg {
        margin-top: 0%;
        width: 45%;
        margin-left: 27%;
    }

    .searchBarHome,
    .searchBarMobileHome {
        width: 70%;
    }

    #landingPageLeft {
        float: none;
        width: 100%;
        padding-left: 0%;
    }

    #landingPageSecondItem {
        text-align: center;
        padding-top: 0%;
        font-size: 30px;
        margin-bottom: 2%;
    }


    #titleHomeRowOne {
        width: 40%;
    }

    #landingPageHomeSubTitle {
        font-size: 15px;
    }

    .homeProductList {
        /* margin-left: 5%; */
        /* width: 90%; */
    }

    .blackBtn {
        padding: 10px 15px 10px 15px;
    }

    #rowThreeBtn {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }

    #landingPageHomeSubDescription_2 {
        width: 100%;
    }

    #rowFourLeft {
        padding-left: 0;
        padding: 15px;
        text-align: center;
        float: none;
        width: 100%;
    }

    #rowFourRight {
        float: none;
        width: 100%;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }


    .searchBarMobileHome {
        position: relative;
        left: 2%;
        width: 96%;
        display: block;
        padding: 8px 10px;

    }

    #rowThreepic {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 15px;
    }

    #rowThree {
        float: none;
        width: 100%;
        padding: 15px;
        text-align: center;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    #rowFourText {
        width: 100%;
    }

    .gifIcon {
        width: 10%;
    }

    .linkLabel,
    #landingPageHomeSubDescription_2,
    .blackBtn,
    #rowFourText,
    #rowFourRight {
        font-size: 16px;
    }

    #rowFourSubTitle,
    #landingPageHomeSubTitle,
    .headerRow {
        font-size: 20px;
    }
}

@media only screen and (min-width: 1100px) and (max-width: 1200px) {
    #landingPage {
        min-height: 53vh;
    }

    #landingPageRight {
        float: none;
        width: 100%;
    }

    #landingPageHomeImg {
        margin-top: 0%;
        width: 45%;
        margin-left: 27%;
    }    

    #landingPageSecondItem {
        text-align: center;
        padding-top: 0%;
        font-size: 30px;
        margin-bottom: 2%;
    }


    #titleHomeRowOne {
        width: 40%;
    }



    .homeProductList {
        /* margin-left: 5%; */
        /* width: 90%; */
    }
    
    #rowThreeBtn {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }

    #landingPageHomeSubDescription_2 {
        width: 100%;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1920px) {

    #landingPage {
        min-height: 53vh;
    }

    #landingPageRight {
        float: none;
        width: 100%;
    }

    #landingPageHomeImg {
        margin-top: 0%;
        width: 45%;
        margin-left: 27%;
    }    

    #landingPageSecondItem {
        text-align: center;
        padding-top: 0%;
        font-size: 30px;
        margin-bottom: 2%;
    }



    .homeProductList {
        /* margin-left: 5%; */
        /* width: 90%; */
    }

    #rowThreeBtn {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }

    #landingPageHomeSubDescription_2 {
        width: 100%;
    }


}

/* @media only screen and (min-width: 1100px) and (max-width: 1280px) {
    #landingPage {
        position: relative;
        min-height: 30vh;
    }


    #landingPageSecondItem {
        font-size: 30px;
    }



    #landingPageHomeImg {
        height: 400px;
    }

    .headerRow {
        font-size: 20px;
    }
} */


/* @media only screen and (min-width: 1280px) and (max-width: 1366px) {
    #landingPage {
        position: relative;
        min-height: 30vh;
    }

    #landingPageSecondItem {
        font-size: 40px;
    }


    #landingPageHomeImg {
        height: 400px;
    }


    .headerRow {
        font-size: 30px;
    }
}

@media only screen and (min-width: 1366px) and (max-width: 1440px) {
    #landingPage {
        position: relative;
        min-height: 30vh;
    }


    #landingPageSecondItem {
        font-size: 40px;
    }



    .homeProductList {
        margin-left: 7%;
    }
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
    #landingPage {
        min-height: 60vh;
    }

    #landingPageSecondItem {
        font-size: 40px;
    }


}

@media only screen and (min-width: 1600px) and (max-width: 1680px) {
    #landingPage {
        position: relative;
        min-height: 30vh;
    }



} */

/* @media only screen and (min-width: 1680px) and (max-width: 1920px) {
    #landingPage {
        position: relative;
        min-height: 30vh;
    }



} */

/* @media only screen and (min-width: 1920px) and (max-width: 2560px) {
    #landingPage {
        position: relative;
        min-height: 30vh;
    }


    #landingPageSecondItem {
        font-size: 60px;
    }

    #landingPageHomeImg {
        width: 50%;
    }


} */

/* @media only screen and (min-width: 2560px) and (max-width: 3840px) {
    #landingPage {
        position: relative;
      
    }


    #landingPageSecondItem {}

    #landingPageHomeImg {
        width: 55%;
       
    }





} */

/* @media only screen and (min-width: 3840px) {
    #landingPage {
        position: relative;
        min-height: 30vh;
    }


    #landingPageSecondItem {
        font-size: 90px;
    }

    #landingPageHomeImg {
        width: 55%;
        height: 1000px;
    }



    #landingPageHomeSubTitle,
    .headerRow {
        font-size: 60px;
    }

    #landingPageHomeSubDescription_2,
    .blackBtn,
    #rowFourText,
    #rowFourSubTitle,
    .searchBarHome {
        font-size: 40px;
    }

    #rowFourSubDescription {
        font-size: 30px;
    }

} */

@-webkit-keyframes slideRightReturn {
    0% {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  
    100% {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
  }
  @keyframes slideRightReturn {
    0% {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  
    100% {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
  }

  @-webkit-keyframes slideLeftReturn {
    0% {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  
    100% {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
  }
  @keyframes slideLeftReturn {
    0% {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  
    100% {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
  }
  @-webkit-keyframes perspectiveUpReturn {
    0% {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: perspective(800px) rotateX(180deg);
      transform: perspective(800px) rotateX(180deg);
    }
  
    100% {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: perspective(800px) rotateX(0deg);
      transform: perspective(800px) rotateX(0deg);
    }
  }
  @keyframes perspectiveUpReturn {
    0% {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: perspective(800px) rotateX(180deg);
      transform: perspective(800px) rotateX(180deg);
    }
  
    100% {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: perspective(800px) rotateX(0deg);
      transform: perspective(800px) rotateX(0deg);
    }
  }