.leftThirdParty{
    float: left;
    width: 30%;
    margin-left: 10%;
    margin-bottom: 1%;
    background-color: #8AD7CB;
    padding: 1%;
    border-radius: 5%;
}

.rightThirdParty{
    float: right;
    width: 30%;
    margin-right: 10%;
    margin-bottom: 1%;
    background-color: #8AD7CB;
    padding: 1%;
    border-radius: 5%;
}

.PLLabel{
    display: inline-block;
    width: 200px;
    font-weight: bold;
}

.PLQuestion{
    font-weight: bold;
}

.PLinput{
    padding: 0.5% 6.2% 1% 0.5%;
    margin-bottom: 1%;
}
.PL-dropdown{
    padding: 0.5% 12% 1% 0.5%;
    font-size: 16px;
    margin-bottom: 1%;
}
.PLOption{
    margin: 0.5% 0.5% 0.5% 0.5%;
    display: inline-block;
}

.PLFinishingLabel{
    margin-top: 3%;
    display: inline-block;
    font-weight: bold;
    margin-right: 2%;
}

#quantity{
    display: inline-block;
    margin-top: 1%;
    margin-left: 2%;
    margin-right: 2%;
}