#accountBtn {
  float: left;
  margin-top: 1%;
  margin-left: 1%;
  width: 88%;
}
.toggleDivAccount{
  display: flex;
  float: left;
  position: relative;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
}

.toggleBtnAccount {
  padding: 8px 20px;
  color: black;
  border: none;
  background-color: rgba(244, 245, 247, 0.75);
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggleBtnAccount.active {
  background-color: #33434D;
  /* Green color */
  /* font-weight: bold; */
  color: white
}
#accountBtn-logout {
  float: right;
  margin-top: 1%;
  width: 10%;
}

#accountBtn button {
  margin-right: 1%;
}


/* MESSAGE TAB */
#messageLeft {
  /* float: left; */
  /* width: 25%;
  overflow-y: scroll;
  background-color: white;
  height: 95vh;
  margin-top: 5vh; */
  float: left;
  width: 25%;
  overflow-y: scroll;
  background-color: white;
  height: 95vh;
  /* margin-top: 5vh; */
  display: flex;
  flex-direction: column;
}

#messageLeft::-webkit-scrollbar {
  width: 0.25rem;
}

#messageLeft::-webkit-scrollbar-track {
  background: #dbdbdb;
}

#messageLeft::-webkit-scrollbar-thumb {
  background: #333333;
}



/**/
#messageTable {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
#messageTable td:not(:first-child) {
  width: 18%;
}

#messageTable td:first-child {
  width: 2%;
}

#messageTable td:last-child {
  width: 80%;
}
/* #messageTable td:not(:first-child) {
  width: 5%;
}
#messageTable td:not(:second-child) {
  width: 15%;
}

#messageTable td:last-child {
  width: 80%;
} */

#chatNotificationCircle{
  float: right;
  width: 5%;
  height: 11px;
  border-radius: 100%;
  background-color: #316190;
}
#messageTable tr td {
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

#messageTable tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

#messageTable tr img {
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

#tableTitle {
  font-weight: bold;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 5%;
  border: #ccc 1px solid;
}


.toggleBtnAccountListingView {
  margin-right: 2%;
  display: inline-flex;
  align-items: center;
  background-color: #C4E5E0;
  /* background-color: #33434D; */
  border-radius: 10px;
  padding: 8px 10px;
  cursor: pointer;
  position: relative; /* Add position: relative for circle positioning */
 
}

.toggled {
  justify-content: flex-end;
}

.toggled .circle {
  left: calc(100% - 25px); /* Move the circle to the right when toggled */
}

.circle {
  width: 15px;
  height: 15px;
  /* background-color: #C4E5E0; */
  background-color: #33434D;
  border-radius: 50%;
  margin-right: 5px;
  position: absolute; /* Set position: absolute for circle positioning */
  left: 5px; /* Initial position on the left */
  transition: left 0.3s ease-in-out; /* Add transition for smooth movement */
 
}

.toggleBtnAccountListingView span {
  /* color: white; */
  
  transition: margin-left 0.3s ease-in-out; /* Add transition property for smooth animation */
  position: relative; /* Add position: relative to keep the text within the button */

}



/**/

#chatboxLeft_Message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: gray;
  font-size: 14px;
  text-align: left;
}

#chatEmpty {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#messageRight {
  width: 100%;
  background-color: #FAFAFA;
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* position: relative; */
}

#mainMessage{
  overflow-y: scroll;
  flex-grow: 1;
  /* height: 100vh; */
}

#mainRight {
  float: right;
  width: 75%;
  background-color: #FAFAFA;
  /* overflow-y: scroll; */
  border-radius: 20px;
  height: 100vh;
  position: relative;
  /* margin-top: 5vh; */
}

#messageRightForm {
  /* float: right; */
  /* position: absolute; */
  /* bottom: 2px; */
  width: 100%;
  /* margin: 1% */
}

#mainMessage::-webkit-scrollbar {
  width: 0.25rem;
}

#mainMessage::-webkit-scrollbar-track {
  background: #222222;
}

#mainMessage::-webkit-scrollbar-thumb {
  background: #a0a0a0;
}

#meProfilePic {
  float: right;
  background: #e5e5ea;
  align-self: flex-end;
  border-radius: 10px;
  margin: 1% 1% 1% 0;
}

.me {
  float: right;
  color: white;
  background: #316190;
  align-self: flex-end;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
  margin: 1% 1% 1% 0;
  max-width: 60%;
  word-wrap: break-word;
}

#otherProfilePic {
  float: left;
  background: #e5e5ea;
  align-self: flex-end;
  border-radius: 10px;
  margin: 1% 0 1% 1%;

}

.btn_message {
  background-color: #33434D;
  color: #fff;
  padding: 8px 20px;
  border-radius: 10px;
  transition: all 0.3s ease-out;
  cursor: pointer;
  border: transparent;
  font-size: 15px;
}

.otherParty {
  float: left;
  background: #8cd1c7;
  color: black;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
  margin: 1% 0 1% 1%;
  word-wrap: break-word;
  max-width: 60%;
}

#completeLabel {
  margin-left: 1%;
}

#completeInput {
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
}

#messageLabelDescription {
  float: left;
  padding-left: 2%;
  border-radius: 10px;
  line-height: 1.5;
  width: 70%;
}

#messageRightForm form textarea {
  height: 7vh;
  width: 93%;
  border-radius: 10px;
  padding: 1%;
  border: none;
  outline: none;

}
#closedOverlayImg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(69, 69, 69, 0.8);
}

#imageLink{
  width: 300px;
  height: auto;
  cursor: pointer;
}
#documentLink{
  font-size: 1rem;
  color: black;
  text-decoration: none;
}

#messageRightForm form button {
  width: 7%;
  height: 7vh;
  background-color: transparent;
  color: #33434D;
  border-radius: 10px;
  padding: 8px;
  font-size: 15px;
  outline: none;
  border: transparent;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
}

#messageImage, #messagePaperClip{
  cursor: pointer;
  font-size: 1.5rem;
}
#messagePaperClip{
  margin-left: 1%;
  margin-right: 1%;
}


#leftAccount {
  /* display: flex;
  flex-direction: column;
  float: left;
  align-items: center;
  justify-content: space-around;
  width: 10%; */
  /* margin-top: 2%; */
  /* min-height: 100vh;
  padding-top: 1%; */
  /* border: 1px solid #FAFAFA; */
  /* border-radius: 10px;
  background-color: #c4e5e0; */
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 15%;
  min-height: 100vh;
  border-radius: 10px;
  background-color: #C4E5E0;
}

#leftAccountTab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  /* push the tab to the top */
  width: 100%;
  /* make the tab span the full width */
  height: calc(100% - 60px);
}

#leftAccount i {
  margin-right: 10%;
}

.account-btn {
  border: none;
  padding: 20px 20px;
  background-color: transparent;
  cursor: pointer;
  width: 98%;
  margin-left: 2%;
  text-align: left;
  border-radius: 10px;
}

.activeAccount {
  border: none;
  padding: 20px 20px;
  background-color: white;
  cursor: pointer;
  text-align: left;
  margin-left: 2%;
  width: 98%;
  border-left: #1e1e24 4px solid;
}

#pendingMessageTitle {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 3%;
}



.account-btnNormal {
  background-color: #33434D;
  color: white;
  padding: 8px 20px;
  border-radius: 10px;
  cursor: pointer;
}

.pendingMessagePhoto {
  width: 5%;
  height: 50%;
}

.pendingBackground {
  background-color: #FAFAFA;
  padding: 30px;
  margin-top: 1%;
  border-radius: 10px;
}

#pendingTable {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.pendingTableLeftShift {
  text-align: left;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
}

.pendingTableRetract {
  padding: 8px 20px;
  border: none;
  background-color: #C4E5E0;
  border-radius: 10px;
  cursor: pointer;
}

#pendingTable tr td {
  padding: 10px;
}

#pendingTable td:first-child {
  padding: 5px;
  width: 11%; /* Set the width of the first column to fit the content */
  white-space: nowrap; /* Prevent the content from wrapping */
}

#pendingTable th {}

#pendingTable td {}


#messageBackBtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 2%;
}

#rightAccount {
  float: right;
  width: 85%;
}

#topInfomationChat {
  display: flex;
  position: relative;
  border: #ccc 1px solid;
  padding: 8px 0px;
}

#leftAccountMessageClick {
  display: none;
}

#rightAccountMessageClick {
  width: 85%;
  float: right;
}

#profilePic {
  width: 200px;
  height: 200px;
  border: 4px groove #8AD7CB;
  border-radius: 50%;
}

.logout-btn {
  /* background-color: #33434D;
  color: white;
  padding: 8px 20px;
  border-radius: 10px;
  cursor: pointer; */
  /* margin-top: 20%; */
  /* margin-left: 10%; */
  background-color: #33434D;
  color: white;
  padding: 8px 20px;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 10%;
  margin-top: auto;
  /* push the button to the bottom */
}

#profileDetails {
  margin-top: -10%;
  width: 100%;
  min-height: 500px;
  z-index: -1;
  padding-top: 10%;
  padding-left: 2%;
  border: 4px groove #8AD7CB;
  border-radius: 30px;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #32444e86;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #32444e86;
}

.CardAccount{
  float: left;
  transition: 0.3s;
  width: 23%;
  /* aspect-ratio: 4/5; */
  height: 460px;
  margin: 1%;
}
.CardResidentialAccount{
  float: left;
  transition: 0.3s;
  width: 23%;
  /* aspect-ratio: 4/5; */
  height: 260px;
  margin: 1%;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  position: relative;
  padding: 5px;
}

.CardPurchaseAccount{
  float: left;
  transition: 0.3s;
  width: 23%;
  /* aspect-ratio: 4/5; */
  height: 330px;
  margin: 1%;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  position: relative;
  padding: 5px;
}
.CardResidentialSaleAccount{
  float: left;
  transition: 0.3s;
  width: 23%;
  /* aspect-ratio: 4/5; */
  height: 260px;
  margin: 1%;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  position: relative;
  padding: 5px;
}

.CardAccount a, .CardResidentialAccount a, .CardPurchaseAccount a, .CardResidentialSaleAccount a{
  color: black;
  text-decoration: none;
  cursor: pointer;
}


.listingCard {
  float: left;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 300px;
  margin: 3%
}

.listingCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  padding: 2px 16px;
}

#listingCard_type,
#listingCard_overall,
#listingCard_feet {
  padding-top: 5%;
  color: #808080;
  padding-left: 5%;
}

#listingCard_district {
  padding-top: 5%;
  font-size: 20px;
  padding-left: 5%;
}



#listingTab {
  /* display: flex;
  justify-content: right;
  width: 90%; */
}

.account-btnInsideCard {
  background-color: #33434D;
  color: white;
  padding: 8px 20px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
}

.systemNoti{
  text-align: center;
  padding: 8px 5px 8px 5px;
  border: 1px solid #ccc;
  margin: 10px;
  border-radius: 10px;
}
.btn-basicListing {
  background-color: white;
  color: rgb(98, 125, 177);
  border-radius: 50px;
  margin: 1%;
  padding: 8px 20px 8px 20px;
  font-size: 18px;
  outline: none;
  border: 2px solid rgb(98, 125, 177);
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
}

.btn-basicListing:hover {
  transition: all 0.3s ease-out;
  background: rgba(98, 125, 177, 0.47);
  transition: 250ms;
}


.offerButton {
  background-color: white;
  color: rgb(98, 125, 177);
  border-radius: 50px;
  margin: 2px 3px 2px 3px;
  padding: 4px 5px 4px 5px;
  font-size: 14px;
  outline: none;
  border: 2px solid rgb(98, 125, 177);
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
}

.offerButton:hover {
  transition: all 0.3s ease-out;
  background: rgba(98, 125, 177, 0.47);
  transition: 250ms;
}


#accountTabLeft {
  float: left;
  width: 70%;
}

#accountTabRight {
  float: right;
  width: 30%;
}

.accountTabDiv {
  background-color: #316190;
  margin: 1%;
  padding: 1%;
  border-radius: 10px;
}


#accountPageTitle {
  margin: 3% 5%;
  font-size: 30px;
  font-weight: bold;
}


#accountPageDiv {
  margin-left: 5%;
  width: 90%;
  border-radius: 10px;
  background-color: #FAFAFA;
}

#accountButton {
  border-bottom: 1px #ccc solid;
  background-color: #FAFAFA;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

}

#addBusinessAccountDiv {
  padding-left: 20%;
  margin-top: 5%;
}

.accountButtonActive {
  color: black;
  border: none;
  background-color: transparent;
  padding: 30px 50px;
  cursor: pointer;
  font-weight: bold;
}

.accountButtonNotActive {
  border: none;
  background-color: transparent;
  padding: 30px 50px;
  cursor: pointer;
  color: grey;
}


.accountTabContent,
#accountViewProperty,
#accountPremium {
  padding: 10px 20px;
}



#accountDetailsPic {
  max-width: 150px;
  max-height: 150px;
  float: left;
  margin-left: 2%;
  margin-top: 3%;
}

#accountDetailsInfo {
  float: right;
  width: 80%;
  /* margin-left: 10%; */
}

.accountDetailsInfoAnswer {
  color: grey
}

.accountDetailsInfoTitle {
  font-weight: bold;
}

.accountTabPassword {
  margin-top: 2%;
  width: 98%;
  margin: 1%;
  padding: 10px 0px;
  border-radius: 10px;
  border: 1px grey solid;
}

#passwordTabResetBtn {
  margin-top: 2%;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  background-color: #33434D;
  color: white;
  cursor: pointer;
}

#passwordTabDeleteBtn{


  width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
  background-color:  #be3434;
  color: white;
}
#settingBtn{
  width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #64748b;

  cursor: pointer;
}

#viewPropertyTitle {
  font-size: 20px;
  font-weight: bold;
  float: left;
}

#viewPropertyBtn {
  float: right;
  background-color: #C4E5E0;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

#indivAccountPropertyCompanyName {
  font-weight: bold;
}

#indivAccountPropertyAddress {
  color: grey;
  margin-bottom: 2%;
}

.notificationCircle {
  /* background-color: #33434D;
  border-radius: 50%; */

  padding: 5px;
  font-weight: bold;
  font-size: 16px;
  color: #33434D;
  display: inline;
}

.dashboardArea {
  margin: 2%;
  margin-left: 5%;
  margin-right: 5%;
}

.dashboardTitle {
  float: left;
  color: #316190;
  font-size: 30px;
  font-weight: bold;
  display: inline-flex;
}

.dashboardMyDashboard {
  float: right;
  font-size: 30px;
  font-weight: bold;
  color: #58B2A4;
}

.dashboardOverview {
  font-size: 20px;
  float: left;
  margin-top: 5%;
  font-weight: bold;
}

.dashboardOverviewDiv {
  min-height: 400px;
  background-color: #FAFAFA;
  border-radius: 10px;
  padding: 10px 10px;
  margin: 1%;
}

.dashboardOverviewMessageDiv {
  background-color: white;
  width: 48%;
  margin: 1%;
  /* float: left; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 10px;
  /* height: 200px; */
  /* margin-bottom: 2%; */
  border-radius: 10px;
  position: relative;
}

.dashboardOverviewNumber {
  font-size: 50px;
  font-weight: bold;
}

.dashboardOverviewMessage {
  font-size: 20px;
  padding-bottom: 30px;
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.dashboardOverviewMessageBtn {
  padding-top: 10px;
  padding-bottom: 10px;
  /* position: absolute; */
  /* bottom: 0; */
  /* margin-bottom: 3%; */
  border: none;
  background-color: transparent;
  color: #316190;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
}

.dashboardListingDiv {
  /* float: right; */
  width: 48%;
  background-color: white;
  padding: 10px 10px;
  min-height: 350px;
  margin: 1%;
  border-radius: 10px;
}

.dashboardNewListingDiv {
  /* color: white; */
  /* background-color: #316190; */
  padding: 5px 5px;
  width: 100%;
  border-radius: 10px;
  /* margin-top: 2%; */
}

.dashboardListingNewListing {
  float: left;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  width: 50%;
  padding: 10px 10px;
  text-align: center;
}

.dashboardConnectionNewListing {
  float: right;
  border-top: 1px solid #ccc;
  width: 50%;
  padding: 10px 10px;
  text-align: center;
}

.dashboardListingTitle,
.dashboardListingDropdown {
  padding: 10px 10px;
  border: none;
  font-weight: bold;
  font-size: 17px;
  color: #316190;
}

.dashboardNewListingNumber {
  font-size: 25px;
  font-weight: bold;
}

.dashboardTenantDropdown {
  padding: 8px 8px;
  color: black;
  border: none;
  background-color: #FAFAFA;
  border-radius: 10px;
}


#accountPicDiv{
  float: left;
  width: 20%;
}

.dashboardNewListingBtn {
  float: right;
  border: none;
  background-color: transparent;
  font-weight: bold;
  color: #8CD1C7;
  margin-right: 2%;
  font-size: 16px;
  cursor: pointer;
}

.dashboardNewListingStatement {
  float: left;
  font-weight: bold;
}

.dashboardListingDropdown {
  padding: 0;
}

.dashboardOverviewMessage_PendingDiv {
  /* float: left; */
  display: flex;
  /* width: 48%; */
  flex-direction: row;
}

.dashboardChangeView {
  color: black;
  float: right;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px;
  margin-top: 5%;
}

.addBusinessAccount {
  float: right;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  background-color: #33434D;
  color: white;
  cursor: pointer;
}

.modalAddBusinessAccountLeft {
  float: left;
  width: 50%;
  margin-bottom: 13px;
}

.modalAddBusinessAccountRight {
  float: right;
  width: 50%;
  margin-bottom: 2%;
}

.modalAddBusinessAccountRight select,
.modalAddBusinessAccountRight input,
.modalAddBusinessAccountLeft input {
  width: 90%;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
}

.modalAddBusinessAccountRight p,
.modalAddBusinessAccountLeft p {
  font-weight: bold;
  margin-bottom: 1%;
}

.AddBusinessBtn {
  padding: 10px 20px;
  border: none;
  background-color: #C4E5E0;
  /* border-radius: 10px; */
  margin-right: 1%;
  float: right;
  cursor: pointer;
}

.accountBusinessLeft {
  float: left;
  width: 50%;
  /* padding-left: 5%; */
}

.accountBusinessLabel {
  font-weight: bold;
}

.accountBusinessAnswer {
  margin-bottom: 5%;
  color: grey;
}

#accountBusinessSelect {
  /* margin-left: 5%; */
  padding: 10px 0px;
  background-color: transparent;
  border-radius: 10px;
}

.accountBusinessRight {
  float: right;
  width: 50%;
}

.icon-tooltip {
  position: relative;
}

.tooltip-content {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: none;
}

.icon-tooltip:hover .tooltip-content {
  display: block;
}

.dashboardTenantListingDropDown {
  /* margin-top: 2%; */
  color: black;
  border-radius: 10px;
  background-color: transparent;
  padding: 10px;
  border: 1px solid grey;
}

#messageThreeDot{
  position: absolute;
  top:0;
  right: 0;
  margin-right: 2%;
  margin-top: 1%;
  font-size: 1.4rem;
  cursor: pointer;
}

#messageExpandedIcon{
  position: absolute;
  bottom:0;
  right: 0;
  margin-right: 2%;
  margin-top: 1%;
  font-size: 1.4rem;
  cursor: pointer;
  color:#33434D;
}
.ellipsis-menu{
  position: absolute;
  top: 30px;
  right: 5px;
  display: block;
  background-color: #fafafa;
  padding: 5px;
  border-radius: 5px;
}
.ellipsis-menu ul{
  text-align: right;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.ellipsis-menu ul li {
  cursor: pointer;
  padding: 5px;
}
.ellipsis-menu ul li:hover {
  background-color: #ddd;
}

#backarrow{
  font-size: 20px;
}

#dashboardAccountLowerPart{
  display: flex;
  flex-direction: row;
}
#dashboardAccountLowerPart2{
  display: flex;
  flex-direction: column;
  width: 50%;
}
#notificationIcon{
  color: #33433d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  width: 3%;
}
.dashboardComment{
  width: 90%;
  padding: 5px;
}

#chatSafetyTip{
  display: flex;
  text-align: left;
  padding-left: 20px;
  flex-direction: column;
}
#planDetails{
  margin-top: 4%;
}
/* @media screen and (max-width: 1100px) {
#accountBtn-logout{
  margin-right: 2%;
}
#accountBtn{
  width: 80%;
}
}

@media screen and (max-width: 780px) {
  #accountBtn-logout{
    margin-right: 5%;
  }
  #accountBtn{
    width: 80%;
  }
}

@media screen and (max-width: 650px) {
  #accountBtn-logout{
    margin-right: 10%;
  }
  #accountBtn{
    width: 70%;
  }
}

@media screen and (max-width: 480px) {
  #accountBtn-logout{
    margin-right: 15%;
  }
  #accountBtn{
    width: 65%;
  }
}

@media screen and (max-width: 380px) {
  #accountBtn-logout{
    margin-right: 20%;
  }
  #accountBtn{
    width: 60%;
  }
} */
@media only screen and (max-width: 412px) {
  .accountTabContent{
    padding: 10px 0px;
  }
  #planDetails{
    margin-top: 30%;
  }
  #accountViewProperty{
    padding: 10px 5px;
  }
  #accountDetailsInfo {
    margin-left: 5%;
    width: 90%;
    float: none;
  }
  .toggleDivAccount{
    float: none;
    margin-bottom: 3%;
    justify-content: center;
    margin-left: 0%;
  }
  #messageRightForm form textarea{
    width: 80%;
  }
  .CardResidentialAccount{
    width: 90%;
    height: 240px;
    /* aspect-ratio: 4/4; */
  }
  .CardResidentialSaleAccount{
    width: 90%;
    height: 240px;
    /* aspect-ratio: 4/4; */
  }
  .CardPurchaseAccount{
    width: 90%;
    height: 350px;
  }
  .CardAccount{
    width: 90%;
    /* height: 400px; */
    /* aspect-ratio: 4/4; */
  }
  #listingTab{
    width: 100%;
  }
  .toggleBtnAccountListingView{
    margin-right: 1%;
    font-size: 12px;
  }
  #notificationIcon,.dashboardComment{
    font-size: 12px;
  }
  #dashboardAccountLowerPart{
    display: block;
  }
  #dashboardAccountLowerPart2{
    width: 100%;
  }
  .addBusinessAccount,#passwordTabResetBtn,#passwordTabDeleteBtn,#viewPropertyBtn{
    padding: 8px 7px;
    font-size: 11px;
  }
  #pendingTable td:first-child {
    display: none;
  }
  #imageLink{
    width: 100px;
    
  }
  #topInfomationChat img{
    display: none;
  }
  .modalAddBusinessAccountRight,.modalAddBusinessAccountLeft{
    float: none;
    width: 100%;

  }
  .account-btnNormal{
    padding: 8px 10px;
  }
  #leftAccount {
    display: none;
  }

  #rightAccount {
    float: none;
    width: 100%;
  }

  #listingContent {
    padding-left: 5%;
    margin-top: 5%;
  }

  #messageLeft {
    float: none;
    width: 100%;
  }

  #mainRight {
    width: 100%;
    display: none;
    float: none;
  }

  #accountDetailsPic {
    width: 40px;
    height: 40px;
  }

  #addBusinessAccountDiv {
    padding-left: 0%;
  }



  .dashboardOverviewMessage_PendingDiv {
    float: none;
    width: 100%;
    flex-direction: row;
  }

  .dashboardOverviewMessageDiv {
    width: 50%;
  }

  .dashboardListingDiv {
    min-height: 250px;
    float: none;
    width: 100%;
  }

  #accountPicDiv, #accountDetailsPic {
    float: none;
    margin-left: 5%;
  }

  #dashboardAccountPic {
    display: none;
  }

  .dashboardNewListingStatement,
  .dashboardNewListingBtn,
  .dashboardOverviewMessage,
  .dashboardNewListingSubTitle,
  #accountDetailsInfo,
  #addBusinessAccountDiv {
    font-size: 14px;
  }

  .dashboardTitle,
  .dashboardMyDashboard,#lookingSpace,#gotSpace {
    font-size: 16px;
  }

  .dashboardOverview {
    font-size: 16px;
  }

  .accountTabPassword{
    width: 100%;
  }

  .account-btnInsideCard, .me,.otherParty,.btn_message {
    padding: 8px 10px;
    font-size: 12px;
  }

  .pendingBackground {
    padding: 10px;
  }
  #pendingTable tr td{
    padding: 5px 0px;
    font-size: 12px;
  }

  #pendingMessageTitle {
    font-size: 16px;
  }
  .pendingTableRetract{
    padding: 8px 10px;
    font-size: 10px;
  }

  #pendingTable,#messageLabelDescription {
    font-size: 13px;
  }

  #accountPageTitle {
    font-size: 16px;
  }

  .accountButtonActive,
  .accountButtonNotActive {
    font-size: 12px;
    padding: 15px 8px
  }

  .accountDetailsInfoTitle,#viewPropertyTitle {
    font-size: 12px;
  }
  #indivAccountPropertyCompanyName, #indivAccountPropertyAddress{
    font-size: 12px;
  }
  #messageImage, #messagePaperClip{
    margin-left: 1.5%;
    margin-right: 1.5%;
    font-size: 16px; 
  }
  #chatSafetyTip,#tipForLandlord{
    font-size: 12px;
  }
  #tipForLandlord{
    padding-left: 10px;
  }
  /* #chatSafetyTip ul{
    padding-left: 5%;
  } */
}

@media only screen and (min-width: 412px) and (max-width: 540px) {
  .accountTabContent{
    padding: 10px 0px;
  }
  #planDetails{
    margin-top: 30%;
  }
  #accountViewProperty{
    padding: 10px 5px;
  }
  #accountDetailsInfo {
    margin-left: 5%;
    width: 90%;
    float: none;
  }
  .toggleDivAccount{
    float: none;
    justify-content: center;
    margin-left: 0%;
    margin-bottom: 3%;
  }
  #messageRightForm form textarea{
    width: 80%;
  }
  .CardResidentialAccount{
    width: 90%;
    height: 240px;
    /* aspect-ratio: 4/4; */
  }
  .CardPurchaseAccount{
    width: 90%;
    height: 350px;
  }
  .CardResidentialSaleAccount{
    width: 90%;
    height: 240px;
    /* aspect-ratio: 4/4; */
  }
  .CardAccount{
    width: 90%;
    /* height: 400px; */
    /* aspect-ratio: 4/4; */
  }
  #listingTab{
    width: 100%;
  }
  .toggleBtnAccountListingView{
    margin-right: 1%;
    font-size: 12px;
  }
  #notificationIcon,.dashboardComment{
    font-size: 12px;
  }
  #dashboardAccountLowerPart{
    display: block;
  }
  #dashboardAccountLowerPart2{
    width: 100%;
  }
  .addBusinessAccount,#passwordTabResetBtn,#passwordTabDeleteBtn,#viewPropertyBtn{
    padding: 8px 7px;
    font-size: 11px;
  }
  #pendingTable td:first-child {
    display: none;
  }
  #imageLink{
    width: 100px;
   
  }
  #topInfomationChat img{
    display: none;
  }
  .modalAddBusinessAccountRight,.modalAddBusinessAccountLeft{
    float: none;
    width: 100%;

  }
  .account-btnNormal{
    padding: 8px 10px;
  }
  #leftAccount {
    display: none;
  }

  #rightAccount {
    float: none;
    width: 100%;
  }

  #listingContent {
    margin-top: 5%;
    padding-left: 5%;
  }

  #messageLeft {
    float: none;
    width: 100%;
  }

  #mainRight {
    width: 100%;
    display: none;
    float: none;
  }

  #accountDetailsPic {
    width: 40px;
    height: 40px;
  }

  #addBusinessAccountDiv {
    padding-left: 0%;
  }


  .dashboardOverviewMessage_PendingDiv {
    float: none;
    width: 100%;
    flex-direction: row;
  }

  .dashboardOverviewMessageDiv {
    width: 50%;
  }

  .dashboardListingDiv {
    min-height: 250px;
    float: none;
    width: 100%;
  }

  #accountPicDiv, #accountDetailsPic {
    float: none;
    margin-left: 5%;
  }

  #dashboardAccountPic {
    display: none;
  }

  .dashboardNewListingStatement,
  .dashboardNewListingBtn,
  .dashboardOverviewMessage,
  .dashboardNewListingSubTitle,
  #accountDetailsInfo,
  #addBusinessAccountDiv {
    font-size: 14px;
  }

  .dashboardTitle,
  .dashboardMyDashboard {
    font-size: 16px;
  }

  .dashboardOverview {
    font-size: 16px;
  }

  .accountTabPassword{
    width: 100%;
  }

  .account-btnInsideCard, .me,.otherParty,.btn_message {
    padding: 8px 10px;
    font-size: 12px;
  }

  .pendingBackground {
    padding: 10px;
  }
  #pendingTable tr td{
    padding: 5px 0px;
    font-size: 12px;
  }

  #pendingMessageTitle {
    font-size: 16px;
  }
  .pendingTableRetract{
    padding: 8px 10px;
    font-size: 10px;
  }

  #pendingTable,#messageLabelDescription {
    font-size: 13px;
  }

  #accountPageTitle {
    font-size: 16px;
  }

  .accountButtonActive,
  .accountButtonNotActive {
    font-size: 12px;
    padding: 15px 8px
  }

  .accountDetailsInfoTitle,#viewPropertyTitle {
    font-size: 12px;
  }
  #indivAccountPropertyCompanyName, #indivAccountPropertyAddress{
    font-size: 12px;
  }
  #messageImage, #messagePaperClip{
    margin-left: 1.5%;
    margin-right: 1.5%;
    font-size: 16px;
  }
  #chatSafetyTip,#tipForLandlord{
    font-size: 12px;
  }
  /* #chatSafetyTip ul{
    padding-left: 5%;
  } */
}

@media only screen and (min-width: 540px) and (max-width: 768px) {
  .accountTabContent{
    padding: 10px 0px;
  }
  #planDetails{
    margin-top: 30%;
  }
  #accountViewProperty{
    padding: 10px 5px;
  }
  #accountDetailsInfo {
    margin-left: 5%;
    width: 90%;
    float: none;
  }
  .toggleDivAccount{
    float: none;
    justify-content: center;
    margin-left: 0%;
    margin-bottom: 3%;
  }
  #messageRightForm form textarea{
    width: 80%;
  }
  .CardResidentialAccount{
    width: 45%;
    height: 240px;
    /* aspect-ratio: 4/4; */
  }
  .CardResidentialSaleAccount{
    width: 45%;
    height: 240px;
    /* aspect-ratio: 4/4; */
  }
  .CardPurchaseAccount{
    width: 45%;
    height: 350px;
  }
  .CardAccount{
    width: 45%;
    /* aspect-ratio: 4/4; */
  }
  #listingTab{
    width: 100%;
  }
  .toggleBtnAccountListingView{
    margin-right: 1%;
    font-size: 12px;
  }
  #notificationIcon,.dashboardComment{
    font-size: 12px;
  }
  #dashboardAccountLowerPart{
    display: block;
  }
  #dashboardAccountLowerPart2{
    width: 100%;
  }
  .addBusinessAccount,#passwordTabResetBtn,#passwordTabDeleteBtn,#viewPropertyBtn{
    padding: 8px 7px;
    font-size: 11px;
  }
  #pendingTable td:first-child {
    display: none;
  }
  #imageLink{
    width: 100px;
   
  }
  #topInfomationChat img{
    display: none;
  }
  .modalAddBusinessAccountRight,.modalAddBusinessAccountLeft{
    float: none;
    width: 100%;

  }
  .account-btnNormal{
    padding: 8px 10px;
  }
  #leftAccount {
    display: none;
  }

  #rightAccount {
    float: none;
    width: 100%;
  }

  #listingContent {
    padding-left: 5%;
    margin-top: 5%;
  }

  #messageLeft {
    float: none;
    width: 100%;
  }

  #mainRight {
    width: 100%;
    display: none;
    float: none;
  }

  #accountDetailsPic {
    width: 40px;
    height: 40px;
  }

  #addBusinessAccountDiv {
    padding-left: 0%;
  }



  .dashboardOverviewMessage_PendingDiv {
    float: none;
    width: 100%;
    flex-direction: row;
  }

  .dashboardOverviewMessageDiv {
    width: 50%;
  }

  .dashboardListingDiv {
    min-height: 250px;
    float: none;
    width: 100%;
  }

  #accountPicDiv, #accountDetailsPic {
    float: none;
    margin-left: 5%;
  }

  #dashboardAccountPic {
    display: none;
  }

  .dashboardNewListingStatement,
  .dashboardNewListingBtn,
  .dashboardOverviewMessage,
  .dashboardNewListingSubTitle,
  #accountDetailsInfo,
  #addBusinessAccountDiv {
    font-size: 14px;
  }

  .dashboardTitle,
  .dashboardMyDashboard {
    font-size: 16px;
  }

  .dashboardOverview {
    font-size: 16px;
  }

  .accountTabPassword{
    width: 100%;
  }

  .account-btnInsideCard, .me,.otherParty,.btn_message {
    padding: 8px 10px;
    font-size: 12px;
  }

  .pendingBackground {
    padding: 10px;
  }
  #pendingTable tr td{
    padding: 5px 0px;
    font-size: 12px;
  }

  #pendingMessageTitle {
    font-size: 16px;
  }
  .pendingTableRetract{
    padding: 8px 10px;
    font-size: 10px;
  }

  #pendingTable,#messageLabelDescription {
    font-size: 13px;
  }

  #accountPageTitle {
    font-size: 16px;
  }

  .accountButtonActive,
  .accountButtonNotActive {
    font-size: 12px;
    padding: 15px 8px
  }

  .accountDetailsInfoTitle,#viewPropertyTitle {
    font-size: 12px;
  }
  #indivAccountPropertyCompanyName, #indivAccountPropertyAddress{
    font-size: 12px;
  }
  #messageImage, #messagePaperClip{
    margin-left: 1.5%;
    margin-right: 1.5%;
    font-size: 16px;
  }
  #chatSafetyTip,#tipForLandlord{
    font-size: 12px;
  }
  /* #chatSafetyTip ul{
    padding-left: 2%;
  } */
}

@media only screen and (min-width: 768px) and (max-width: 800px) {
  .accountTabContent{
    padding: 10px 0px;
  }
  #planDetails{
    margin-top: 30%;
  }
  #accountViewProperty{
    padding: 10px 5px;
  }
  #accountDetailsInfo {
    margin-left: 5%;
    width: 90%;
    float: none;
  }
  .toggleDivAccount{
    float: none;
    justify-content: center;
    margin-left: 0%;
    margin-bottom: 3%;
  }
  #messageRightForm form textarea{
    width: 80%;
  }
  .CardResidentialAccount{
    width: 45%;
    height: 240px;
    /* aspect-ratio: 4/4; */
  }
  .CardPurchaseAccount{
    width: 45%;
    height: 350px;
  }
  .CardResidentialSaleAccount{
    width: 45%;
    height: 240px;
    /* aspect-ratio: 4/4; */
  }
  .CardAccount{
    width: 45%;
    /* height: 400px; */
    /* aspect-ratio: 4/4; */
  }
  #listingTab{
    width: 100%;
  }
  .toggleBtnAccountListingView{
    margin-right: 1%;
    font-size: 12px;
  }
  #notificationIcon,.dashboardComment{
    font-size: 12px;
  }
  #dashboardAccountLowerPart{
    display: block;
  }
  #dashboardAccountLowerPart2{
    width: 100%;
  }
  .addBusinessAccount,#passwordTabResetBtn,#passwordTabDeleteBtn,#viewPropertyBtn{
    padding: 8px 7px;
    font-size: 11px;
  }
  #pendingTable td:first-child {
    display: none;
  }
  #imageLink{
    width: 100px;
  }
  #topInfomationChat img{
    display: none;
  }
  .modalAddBusinessAccountRight,.modalAddBusinessAccountLeft{
    float: none;
    width: 100%;

  }
  .account-btnNormal{
    padding: 8px 10px;
  }
  #leftAccount {
    display: none;
  }

  #rightAccount {
    float: none;
    width: 100%;
  }

  #listingContent {
    margin-top: 5%;
    padding-left: 5%;
  }

  #messageLeft {
    float: none;
    width: 100%;
  }

  #mainRight {
    width: 100%;
    display: none;
    float: none;
  }

  #accountDetailsPic {
    width: 40px;
    height: 40px;
  }

  #addBusinessAccountDiv {
    padding-left: 0%;
  }


  .dashboardOverviewMessage_PendingDiv {
    float: none;
    width: 100%;
    flex-direction: row;
  }

  .dashboardOverviewMessageDiv {
    width: 50%;
  }

  .dashboardListingDiv {
    min-height: 250px;
    float: none;
    width: 100%;
  }

  #accountPicDiv, #accountDetailsPic {
    float: none;
    margin-left: 5%;
  }

  #dashboardAccountPic {
    display: none;
  }

  .dashboardNewListingStatement,
  .dashboardNewListingBtn,
  .dashboardOverviewMessage,
  .dashboardNewListingSubTitle,
  #accountDetailsInfo,
  #addBusinessAccountDiv {
    font-size: 14px;
  }

  .dashboardTitle,
  .dashboardMyDashboard {
    font-size: 16px;
  }

  .dashboardOverview {
    font-size: 16px;
  }

  .accountTabPassword{
    width: 100%;
  }

  .account-btnInsideCard, .me,.otherParty,.btn_message {
    padding: 8px 10px;
    font-size: 12px;
  }

  .pendingBackground {
    padding: 10px;
  }
  #pendingTable tr td{
    padding: 5px 0px;
    font-size: 12px;
  }

  #pendingMessageTitle {
    font-size: 16px;
  }
  .pendingTableRetract{
    padding: 8px 10px;
    font-size: 10px;
  }

  #pendingTable,#messageLabelDescription {
    font-size: 13px;
  }

  #accountPageTitle {
    font-size: 16px;
  }

  .accountButtonActive,
  .accountButtonNotActive {
    font-size: 12px;
    padding: 15px 8px
  }

  .accountDetailsInfoTitle,#viewPropertyTitle {
    font-size: 12px;
  }
  #indivAccountPropertyCompanyName, #indivAccountPropertyAddress{
    font-size: 12px;
  }
  #messageImage, #messagePaperClip{
    margin-left: 1.5%;
    margin-right: 1.5%;
    font-size: 16px;
  }
  #chatSafetyTip,#tipForLandlord{
    font-size: 12px;
  }
  /* #chatSafetyTip ul{
    padding-left: 2%;
  } */
}

@media only screen and (min-width: 800px) and (max-width: 992px) {
  .accountTabContent{
    padding: 10px 0px;
  }
  #planDetails{
    margin-top: 30%;
  }
  #accountViewProperty{
    padding: 10px 5px;
  }
  #accountDetailsInfo {
    margin-left: 5%;
    width: 90%;
    float: none;
  }
  .toggleDivAccount{
    float: none;
    justify-content: center;
    margin-left: 0%;
    margin-bottom: 3%;
  }
  #messageRightForm form textarea{
    width: 80%;
  }
  .CardResidentialAccount{
    width: 45%;
    height: 240px;
    /* aspect-ratio: 4/4; */
  }
  .CardPurchaseAccount{
    width: 45%;
    height: 350px;
  }
  .CardResidentialSaleAccount{
    width: 45%;
    height: 240px;
    /* aspect-ratio: 4/4; */
  }
  .CardAccount{
    width: 45%;
    /* height: 400px; */
    /* aspect-ratio: 4/4; */
  }
  #listingTab{
    width: 100%;
  }
  .toggleBtnAccountListingView{
    margin-right: 1%;
    font-size: 12px;
  }
  #notificationIcon,.dashboardComment{
    font-size: 12px;
  }
  #dashboardAccountLowerPart{
    display: block;
  }
  #dashboardAccountLowerPart2{
    width: 100%;
  }
  .addBusinessAccount,#passwordTabResetBtn,#passwordTabDeleteBtn,#viewPropertyBtn{
    padding: 8px 7px;
    font-size: 11px;
  }
  #pendingTable td:first-child {
    display: none;
  }
  #imageLink{
    width: 100px;
  }
  #topInfomationChat img{
    display: none;
  }
  .modalAddBusinessAccountRight,.modalAddBusinessAccountLeft{
    float: none;
    width: 100%;

  }
  .account-btnNormal{
    padding: 8px 10px;
  }
  #leftAccount {
    display: none;
  }

  #rightAccount {
    float: none;
    width: 100%;
  }

  #listingContent {
    margin-top: 5%;
    padding-left: 5%;
  }

  #messageLeft {
    float: none;
    width: 100%;
  }

  #mainRight {
    width: 100%;
    display: none;
    float: none;
  }

  #accountDetailsPic {
    width: 40px;
    height: 40px;
  }

  #addBusinessAccountDiv {
    padding-left: 0%;
  }



  .dashboardOverviewMessage_PendingDiv {
    float: none;
    width: 100%;
    flex-direction: row;
  }

  .dashboardOverviewMessageDiv {
    width: 50%;
  }

  .dashboardListingDiv {
    min-height: 250px;
    float: none;
    width: 100%;
  }

  #accountPicDiv, #accountDetailsPic {
    float: none;
    margin-left: 5%;
  }

  #dashboardAccountPic {
    display: none;
  }

  .dashboardNewListingStatement,
  .dashboardNewListingBtn,
  .dashboardOverviewMessage,
  .dashboardNewListingSubTitle,
  #accountDetailsInfo,
  #addBusinessAccountDiv {
    font-size: 14px;
  }

  .dashboardTitle,
  .dashboardMyDashboard {
    font-size: 16px;
  }

  .dashboardOverview {
    font-size: 16px;
  }

  .accountTabPassword{
    width: 100%;
  }

  .account-btnInsideCard, .me,.otherParty,.btn_message {
    padding: 8px 10px;
    font-size: 12px;
  }

  .pendingBackground {
    padding: 10px;
  }
  #pendingTable tr td{
    padding: 5px 0px;
    font-size: 12px;
  }

  #pendingMessageTitle {
    font-size: 16px;
  }
  .pendingTableRetract{
    padding: 8px 10px;
    font-size: 10px;
  }

  #pendingTable,#messageLabelDescription {
    font-size: 13px;
  }

  #accountPageTitle {
    font-size: 16px;
  }

  .accountButtonActive,
  .accountButtonNotActive {
    font-size: 12px;
    padding: 15px 8px
  }

  .accountDetailsInfoTitle,#viewPropertyTitle {
    font-size: 12px;
  }
  #indivAccountPropertyCompanyName, #indivAccountPropertyAddress{
    font-size: 12px;
  }
  #messageImage, #messagePaperClip{
    margin-left: 1.5%;
    margin-right: 1.5%;
    font-size: 16px;
  }
  #chatSafetyTip,#tipForLandlord{
    font-size: 12px;
  }
  /* #chatSafetyTip ul{
    padding-left: 2%;
  } */
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  
  #imageLink{
    width: 250px;
    
  }
  #addBusinessAccountDiv {
    padding-left: 22%;
  }



  #accountDetailsPic {
    width: 100px;
    height: 100px;
  }


}

@media only screen and (min-width: 1024px) and (max-width: 1150px) {
  #notificationIcon{
    width: 6%;
  }
  #addBusinessAccountDiv {
    padding-left: 24%;
  }
  #Card_overall {
  font-size: 12px;
  }
  .CardPurchaseAccount{
    width: 30.6%;
  }
  .CardResidentialSaleAccount{
    width: 30.6%;
  }
  .CardAccount{
    width: 30.6%;
  }
  .CardResidentialAccount{
    width: 30.6%;
  }

}
@media only screen and (min-width: 1051px) and (max-width: 1279px) {
  #notificationIcon{
    width: 6%;
  }
  #addBusinessAccountDiv {
    padding-left: 24%;
  }
   #Card_overall {
  font-size: 12px;
  }
  #cardResidentialUserInfo {
    font-size: 12px;
  }
  /* .CardPurchaseAccount{
    width: 30.6%;
  }
  .CardAccount{
    width: 30.6%;
  }
  .CardResidentialAccount{
    width: 30.6%;
  }  */

}

@media only screen and (min-width: 1280px) and (max-width: 1366px) {
  #addBusinessAccountDiv {
    padding-left: 24%;
  }
  #commercialSaleInfoAccount{
    height: 33%;
  }
  #residentialRentInfoAccount,#residentialPurchaseInfoAccount{
    height: 25%;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1440px) {
  #addBusinessAccountDiv {
    padding-left: 23%;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1600px) {
  
}

@media only screen and (min-width: 1600px) and (max-width: 1680px) {
  /* #addBusinessAccountDiv {
    padding-left: 21%;
  } */
}

@media only screen and (min-width: 1680px) and (max-width: 1920px) {
  /* #addBusinessAccountDiv {
    padding-left: 2%;
  } */
  .CardAccount{
    height: 450px;
  }
}

@media only screen and (min-width: 1920px) and (max-width: 2560px){
  /* #addBusinessAccountDiv {
    padding-left: 25%;
  } */
  .CardAccount{
    height: 425px;
  }
}
@media only screen and (min-width: 2560px) and (max-width: 3840px){
  /* #addBusinessAccountDiv {
    padding-left: 26%;
  } */
  
  .activeAccount,.account-btn,.dashboardListingTitle, .dashboardListingDropdown,.dashboardNewListingStatement,.dashboardTenantListingDropDown,.dashboardOverviewMessageBtn
  ,.account-btnNormal,.account-btnInsideCard,.accountDetailsInfoTitle,#passwordTabResetBtn,.addBusinessAccount,#passwordTabDeleteBtn,.accountButtonActive,
  .accountButtonNotActive,#viewPropertyBtn,.logout-btn{
    /* font-size: 20px; */
  }

  .CardAccount{
    height: 700px;
  }
  .CardResidentialAccount{
    height: 420px;
  }
  .CardPurchaseAccount{
    height: 410px;
  }
  
  
}
@media only screen and (min-width: 3840px){
  /* #addBusinessAccountDiv {
    padding-left: 26%;
  } */
  .activeAccount,.account-btn,.dashboardListingTitle, .dashboardListingDropdown,.dashboardNewListingStatement,.dashboardTenantListingDropDown,.dashboardOverviewMessageBtn
  ,.account-btnNormal,.account-btnInsideCard,.accountDetailsInfoTitle,#passwordTabResetBtn,.addBusinessAccount,#passwordTabDeleteBtn,.accountButtonActive,
  .accountButtonNotActive,#viewPropertyBtn,.logout-btn{
    /* font-size: 30px; */
  }
  
}

.Tooltip {
  background-color: #444;
  color: white;
  font-size: 90%;
  padding: 4px 8px;
  border-radius: 4px;
  box-sizing: border-box;
  width: max-content;
  max-width: calc(100vw - 10px);
}