body {
  margin: 0;
  padding: 0;
}

.navbar {
  background: #FAFAFA;
  height: 80px;
  display: flex;
  justify-content: center;
  -webkit-box-pack: center;
  align-items: center;
  
  -webkit-box-align: center;
  /* font-size: 1.2rem; */
  position: sticky;
  top: 0;
  z-index: 2;

}
.sub-nav-links {
  color: #000000;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  text-decoration: none;
  padding: 0.5em 1em;
  height: 100%;
}

.sub-nav-links:hover {
  background-color: #c0c0c0;
}
.popup{
  background-color: #FAFAFA;
}
.navbar-container {
  /* display: flex;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%; */
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  -webkit-box-align: center;
  padding: 10px;
}

.navbar-logo {
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2em;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
}

.fa-typo3 {
  margin-left: 0.5em;
  font-size: 1.8em;
}

.nav-menu {
  /* display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60%;
  justify-content: center; */
  list-style: none;
  display: flex;
  justify-content: center;
  -webkit-box-pack: center;
  align-items: center;
  -webkit-box-align: center;
  /* margin-right: 2rem; */
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #000000;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  text-decoration: none;
  padding: 0.5em 1em;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #96D2C8;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
}

.fa-bars {
  color: #242424;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

#navLogo{
  width: 150px;
}
#navLogoLinkContainer{
  width: fit-content;
  /* margin-right: auto; */
  margin-left: 10%;
}

.btn--account {
  width: fit-content;
  display: block;
  white-space: nowrap;
  margin-right: 10%;
  background-color: #33434D;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  border-radius: 10px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  cursor: pointer;
}

.dropdown-toggle{
  border: none;
  background-color: transparent;
  text-align: center;
  padding: 2em;
  width: 100%;
  display: table;
}

#phoneViewAccountBtn{
  display: none;
}


@media screen and (max-width: 960px) {
  
  .navbar-container{  
    justify-content: center;
    -webkit-box-pack: center;
  }
  #navLogoLinkContainer{
    margin-right: 0;
    margin-left: 0;
  }
  .NavbarItems {
    position: relative;
  }
  #phoneViewAccountBtn{
    display: block;
  }
  .nav-menu {
    display: none;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
  }

  .nav-menu.active {
    overflow: auto;
    background: #FAFAFA;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2em;
    width: 100%;
    display: table;
  }

  .nav-links:hover{
      border-bottom: none;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  #navLogo{
    width: 150px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.5em;
    cursor: pointer;
  }

  .fa-times {
    color: #010101;
    font-size: 1.5em;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 20%;
    text-decoration: none;
    font-size: 1.5em;
    background-color: transparent;
    color: #010101;
    padding: 14px 20px;
    border: 1px solid #32444E;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #24242452;
    color: #242424;
    transition: 250ms;
  }
  .nav-item,.dropdown-toggle,.nav-links{
    font-size: 12px;
  }
  .nav-item{
    height: auto;
  }
}
@media only screen and (min-width: 2560px) and (max-width: 3840px){
  .nav-item,.btn--account{
    font-size: 20px;
  }
  
}
@media only screen and (min-width: 3840px){
  .nav-item,.btn--account{
    font-size: 25px;
  }
  
}


