.modalLabelBoost{
    color: grey;
    font-weight: bold;
}

.newListingInputBoost{
    padding: 8px 0 8px 0;
    width: 100%;
    text-align: center;
    border: 1px solid #c1c1c1;
}

.modal-period-left{
    float: left;
    width: 50%;
}

.modal-period-right{
    float: right;
    width: 50%;
}
.modal_p{
    margin-top: 5%;
    font-weight: bold;
    margin-left: 0.5%;
}

.notSelectedProperty:hover{
    background-color: #c1c1c17b;
}

.selectedProperty{
    background-color: #c1c1c1;
}
