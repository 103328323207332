#detail-left{
    float: left;
    width: 50%;
    margin-left: 5%;
    margin-top: 3%;
}

#details-district{
    margin-bottom: 4%;
}

#detail-left h4{
    margin: 10px 0 10px 0;
}

#detail-left-inner-left{
    float:left;
    color:grey;
}

#detail-left-inner-right{
    float: right;
    color: grey;
}

#detail-right{
    float: right;
    width: 37%;
    margin-top: 5%;
    margin-right: 3%;
}

#detail-right button{
    float: right;
}

#detail-right-first{
    padding-bottom: 10px;
    border-bottom: 3px solid black;
}

#detail-right-first h4{
    float: left;
}

#share, #shortlist{
    float: right;
    margin-right: 5%;
    
}

#share button, #shortlist button{
    border: none;
    color: black;
    background: white;
    cursor: pointer;
}

.detail-right-childrow{
    border-bottom: 3px dotted rgba(128, 128, 128, 0.736);
    padding-bottom: 10px;
}

#detailResidentialImg{
    float: left;
    width: 15%;
    height: auto;
    border-radius: 10px;
}
#residentialDistrict, #residentialListingName, #residentialMonthly, #residentialMoveIn, #residentialHumanIconAll, #residentialTenantType{
    padding: 5px;
}

#residentialChildIcon, #residentialHumanIcon{
    margin-right: 5px;
}
#residentialHumanIcon{
    font-size: 28px;
}
#residentialChildIcon{
    font-size: 20px;
}

.detail-right-text-left,.detail-right-label-left{
    word-wrap: break-word;
    margin-top: 4%;
    float:left;
    width: 45%;
    text-align: left;
}
.detail-right-text-right,.detail-right-label-right{
    word-wrap: break-word;
    margin-top: 4%;
    float:right;
    width: 50%;
    text-align: left;
}


#preferredListingContainer{
    margin-top:2%;
    margin-left: 5%;
    margin-bottom: 2%;
    width: 90%;
}
.preferredListingContainer_child{
    border-bottom: 3px dotted rgba(128, 128, 128, 0.736);
}

.preferredListingContainer-label-left{
    float:left;
    width: 50%;
    margin-top: 1%;
}
.preferredListingContainer-label-right{
    float:left;
    width: 50%;
    margin-top: 1%;
}
.preferredListingContainer-text-left{
    float:left;
    width: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.preferredListingContainer-text-right{
    float:left;
    width: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.detailsBtn{
    background-color: #33434D;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    border-radius: 10px;
    transition: all 0.3s ease-out;
    cursor: pointer;
    margin-top: 3%;
}
#detailImg{
    height: 400px;
    width: 100%;
    object-fit: contain;
}
/* @media screen and (max-width: 1100px) {
    .colCard {
      width:50%;
      display: block;
    }
    .searchBtn{
      width: 60%;
    }
    #popover-contained{
      border-radius: 10px;
      width: 60%;
      background: rgba(255, 255, 255, 0.936);
     }


  } */ 
  @media only screen and (max-width: 412px) {

    #detail-left{
        float: none;
        width: 92%;
        margin: 4%;
    }
    #detail-right{
        float:none;
        width: 92%;
        margin-left: 4%;
        margin-right: 4%;
        margin-top: 20%;
        margin-bottom: 10%;
    }
    #detail-left-inner-left{
        float: none;
    }
    #detail-left-inner-right{
        float: none;
    }
    #preferredListingContainer{
        margin-top: 10%;
        margin-bottom: 10%;
        margin-left: 1%;
        margin-right: 1%;
        width: 96%;
    }
    #detail-left h4,#detail-right-first,#preferredListingContainer{
        font-size: 14px;
    }
    #detail-left-inner-left,#detail-left-inner-right,#detailsMontlyPSF,.detail-right-text-right, .detail-right-label-right,.detail-right-text-left, .detail-right-label-left,.preferredListingContainer-label-right,
    .preferredListingContainer-text-right,.preferredListingContainer-label-left,.preferredListingContainer-text-left,.detailsBtn,#share button, #shortlist button
    {
        font-size: 12px;
    }
  }
  @media only screen and (min-width: 412px) and (max-width: 540px) {
    #detail-left{
        float: none;
        width: 92%;
        margin: 4%;
    }
    #detail-right{
        float:none;
        width: 92%;
        margin-left: 4%;
        margin-right: 4%;
        margin-top: 20%;
        margin-bottom: 10%;
    }
    #detail-left-inner-left{
        float: none;
    }
    #detail-left-inner-right{
        float: none;
    }
  }
  @media only screen and (min-width: 540px) and (max-width: 768px) {
    #detail-left{
        float: none;
        width: 92%;
        margin: 4%;
    }
    #detail-right{
        float:none;
        width: 92%;
        margin-left: 4%;
        margin-right: 4%;
        margin-top: 20%;
        margin-bottom: 10%;
    }
    #detail-left-inner-left{
        float: none;
    }
    #detail-left-inner-right{
        float: none;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 800px) {
    #detail-left{
        float: none;
        width: 92%;
        margin: 4%;
    }
    #detail-right{
        float:none;
        width: 92%;
        margin-left: 4%;
        margin-right: 4%;
        margin-top: 20%;
        margin-bottom: 10%;
    }
    #detail-left-inner-left{
        float: none;
    }
    #detail-left-inner-right{
        float: none;
    }
  }
  @media only screen and (min-width: 800px) and (max-width: 850px) {
    #detail-left{
        float: none;
        width: 92%;
        margin: 4%;
    }
    #detail-right{
        float:none;
        width: 92%;
        margin-left: 4%;
        margin-right: 4%;
        margin-top: 20%;
        margin-bottom: 10%;
    }
    #detail-left-inner-left{
        float: none;
    }
    #detail-left-inner-right{
        float: none;
    }
  }
  @media only screen and (min-width: 850px) and (max-width: 992px) {
    #detail-left{
        float: none;
        width: 92%;
        margin: 4%;
    }
    #detail-right{
        float:none;
        width: 92%;
        margin-left: 4%;
        margin-right: 4%;
        margin-top: 20%;
        margin-bottom: 10%;
    }
    #detail-left-inner-left{
        float: none;
    }
    #detail-left-inner-right{
        float: none;
    }
  }
  @media only screen and (min-width: 1024px) and (max-width: 1280px) {}
  @media only screen and (min-width: 1280px) and (max-width: 1366px) {}
  @media only screen and (min-width: 1366px) and (max-width: 1440px) {}
  @media only screen and (min-width: 1440px) and (max-width: 1600px) {}
  @media only screen and (min-width: 1600px) and (max-width: 1680px) {}
  @media only screen and (min-width: 1680px) and (max-width: 1920px) {}
  @media only screen and (min-width: 1920px) and (max-width: 2560px){
    #detailImg{
        height: 500px;
    }
    #detail-left h4 {
        font-size: 20px;
    }
    #detail-left-inner-left,#detail-left-inner-right,#detailsMontlyPSF,#detail-right-first h4,#preferredListingContainer{
        font-size: 18px;
    }
    .detailsBtn,.detail-right-text-left, .detail-right-label-left{
        font-size: 16px;
    }
  }
  @media only screen and (min-width: 2560px) and (max-width: 3840px){
    #detailImg{
        height: 600px;
    }
    #detail-left h4 {
        font-size: 30px;
    }
    #detail-left-inner-left,#detail-left-inner-right,#detailsMontlyPSF,#detail-right-first h4,#preferredListingContainer{
        font-size: 25px;
    }
    .detailsBtn,.detail-right-text-left, .detail-right-label-left{
        font-size: 20px;
    }
  }
  @media only screen and (min-width: 3840px){
    #detailImg{
        height: 800px;
    }
    #detail-left h4 {
        font-size: 35px;
    }
    #detail-left-inner-left,#detail-left-inner-right,#detailsMontlyPSF,#detail-right-first h4,#preferredListingContainer{
        font-size: 30px;
    }
    .detailsBtn,.detail-right-text-left, .detail-right-label-left{
        font-size: 26px;
    }
  }

  /* @media screen and (max-width: 540px) {
    #detail-left{
        float: none;
        width: 96%;
        margin: 2%;
    }
    #detail-right{
        float:none;
        width: 96%;
        margin: 2%;
    }
    #detail-left-inner-left{
        float: none;
    }
    #detail-left-inner-right{
        float: none;
    }
  }

  @media screen and (max-width: 400px) {
    #detail-left{
        float: none;
        width: 96%;
        margin: 2%;
    }
    #detail-right{
        float:none;
        width: 96%;
        margin: 2%;
    }
    #detail-left-inner-left{
        float: none;
    }
    #detail-left-inner-right{
        float: none;
    }
  }

  @media screen and (max-width: 380px) {
    #detail-left{
        float: none;
        width: 96%;
        margin: 2%;
    }
    #detail-right{
        float:none;
        width: 96%;
        margin: 2%;
    }
  } */